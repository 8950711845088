/** Dependencies */
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Components */
import ExpectedURL from './../../ExpectedURL/ExpectedURL';
import AreYouSure from './../../AreYouSure/AreYouSure';
import Loader from './../../Loader/Loader';

/** Helpers */
import { isNullOrUndefined } from './../../../helpers/functions';
import wsStatus from './../../../helpers/webservice/wsStatus.class';

/** SCSS */
import './ActionMenu.scss';

/** FYI :: Status ID From DB 
  1: Nouveau,
  2: Suivi mots-clés,
  3: Suivi Marché + mots-clés,
  4: Ignoré
*/

function ActionMenu( props )
{
  const {
    labels,
    statusId,
    loadingParams,
    reloadTriggerCallBackFct
  } = props;

  /** Instance Dispatch Hook **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Init State */
  const [ selectedStatus, setSelectedStatus ] = useState( statusId );
  const [ followStatusOpened, setFollowStatusOpened ] = useState( statusId === 2 | statusId === 3 ? true : false );
  const [ expectedUrlOpened, setExpectedUrlOpened ] = useState( false );
  const [ areYouSureOpened, setAreYouSureOpened ] = useState( { opened: false, value: statusId } );

  const handleChange = value => 
  {
    // get old value
    const oldStatusId = selectedStatus;

    // set selected status with value
    setSelectedStatus( value );
    
    // update value in DB
    new wsStatus(
      'action-menu-loader',
      loadingParams.device,
      loadingParams.location,
      dispatch,
      loadingParams.clientID,
      'updateTable'
    ).updateStatus( 
      labels,
      value,
      oldStatusId
    );

    // close are your sure window
    setAreYouSureOpened( { opened: false, value: null } );
  }

  return(
    <div className='status-action-menu-container'>

      {/* Confirm */}
      <AreYouSure
        text={ 
          <Fragment>
            Vous êtes sur le point de modifier le statut des mots-clés sélectionnés en <b>{ !isNullOrUndefined( areYouSureOpened.value ) ? userDatas.status.find( elem => elem.id === areYouSureOpened.value ).label : 'Inconnu' }</b>.<br /><br /> 
            Cette action va réinitialiser leurs catégories.
          </Fragment>
        }
        cancelCallBackFct={ () => setAreYouSureOpened( { opened: false, value: null } ) }
        confirmCallBackFct={ () => !isNullOrUndefined( areYouSureOpened?.value ) ? handleChange( areYouSureOpened.value ) : null }
        isOpened={ areYouSureOpened.opened }
      />

      {/* Loader */}
      <Loader 
        loaderID='action-menu-loader'
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          updateTable: () => typeof reloadTriggerCallBackFct === 'function' ? reloadTriggerCallBackFct() : null
        }}
      />

      <div className={ expectedUrlOpened ? 'expected-url open' : 'expected-url' }>
        {
          expectedUrlOpened ?
            <ExpectedURL 
              keywords={ labels }
              loadingParams={ loadingParams }
              cancelCallBackFct={ () => setExpectedUrlOpened( false ) }
              reloadTriggerCallBackFct={ reloadTriggerCallBackFct }
            />
            : null
        }
      </div>
      <div className='status'>
        <ul>
          <li 
            onClick={ () => selectedStatus !== 1 ? 
              statusId === 2 || statusId === 3 ?
                setAreYouSureOpened( { opened: true, value: 1 } ) 
                : handleChange( 1 )
              : null 
            }
            className={ selectedStatus === 1 ? 'selected' : '' }
          >Nouveau</li>
          <li 
            onClick={ () => selectedStatus !== 4 ? 
              statusId === 2 || statusId === 3 ?
                setAreYouSureOpened( { opened: true, value: 4 } ) 
                : handleChange( 4 )
              : null 
            }
            className={ selectedStatus === 4 ? 'selected' : '' }
          >Ignoré</li>
          <li 
            onClick={ () => setFollowStatusOpened( !followStatusOpened ) }
            className={ ( selectedStatus === 2 || selectedStatus === 3 ) ? 'selected' : '' }
          >Suivi</li>
        </ul>
      </div>
      <div className={ followStatusOpened ? 'follow-status open' : 'follow-status' }>
        <ul>
          <li 
            onClick={ () => selectedStatus !== 2 ? handleChange( 2 ) : null }
            className={ selectedStatus === 2 ? 'selected' : '' }
          >Suivi mots-clés</li>
          <li 
            onClick={ () => selectedStatus !== 3 ? handleChange( 3 ) : null }
            className={ selectedStatus === 3 ? 'selected' : '' }
          >Suivi marché + mots-clés</li>
        </ul>
      </div>
      <div className='action'>
        <ul>
          <li className={ expectedUrlOpened ? 'define-expected-url selected' : 'define-expected-url' } onClick={ () => setExpectedUrlOpened( !expectedUrlOpened ) }>Définir URL attendue</li>
          <li className='new-page'>Créer une page</li>
        </ul>
      </div>

    </div>
  )
}

export default ActionMenu;