/** Dependencies */
import { useEffect, useRef, useState } from 'react';
import { SwitchButton } from 'smart-webcomponents-react/switchbutton';

/** Components */
import TooltipInfos from './../TooltipInfos/TooltipInfos';

/** Helpers */
import { 
  isNullOrUndefined,
  getSumFromArrayOfObject,
  getAvgFromArrayOfObject
} from "./../../helpers/functions";

/** SCSS */
import './ChartSelector.scss';

function ChartSelector( props )
{
  const {
    chartDatas,
    callBackFct
  } = props;

  /** UseRef Hook */
  const switchButton1Ref = useRef( null );
  const switchButton2Ref = useRef( null );
  const switchButton3Ref = useRef( null );
  const switchButton4Ref = useRef( null );

  /** UseState Hook */
  const [ selectedChart, setSelectedCharts ] = useState({
    impressions: true,
    clicks: true,
    ctr: true,
    position: true
  });
  const [ currentValues, setCurrentValues ] = useState({
    impressions: null,
    clicks: null,
    ctr: null,
    position: null
  });

  /** Call callback function with selected charts */
  useEffect( () => 
  {
    if( typeof callBackFct == 'function' )
      callBackFct( selectedChart );
    
  }, [ selectedChart ]);

  /** Update global values for indicators */
  useEffect( () => 
  {
    if( !isNullOrUndefined( chartDatas ) )
    {
      // get datas
      const currentImpressions = getSumFromArrayOfObject( chartDatas, 'current-impressions' );
      const currentClicks = getSumFromArrayOfObject( chartDatas, 'current-clicks' );
      const currentCTR = currentImpressions > 0 ? currentClicks / currentImpressions : 0;
      const currentPosition = getAvgFromArrayOfObject( chartDatas, 'current-position' );
      
      //  impressions
      setCurrentValues( { 
        ...currentValues, 
        ...{ 
          impressions: Intl.NumberFormat( 
          'fr-FR', 
          { notation: 'compact' }).format( currentImpressions ),
          clicks: Intl.NumberFormat( 
            'fr-FR', 
            { notation: 'compact' }).format( currentClicks ),
          ctr: Intl.NumberFormat( 
            'fr-FR', 
            { notation: 'compact', maximumFractionDigits: 2, style: 'percent' }).format( currentCTR ),
          position: Intl.NumberFormat( 
            'fr-FR', 
            { notation: 'compact' }).format( currentPosition ) 
        }
      });
    }
    
  }, [ chartDatas ]);

  return(
    <div className="chart-selector">
      <div className='chart-selector-button-container impressions'>
        <SwitchButton 
          ref={ switchButton1Ref } 
          onChange={ e => setSelectedCharts( { ...selectedChart, ...{ impressions: e.detail.value } } ) } 
          inverted 
          checked={ selectedChart.impressions }
        />
        <p 
          className='label' 
          onClick={ () => 
            (
              switchButton1Ref.current !== null 
              && (
                Object.values( selectedChart ).filter( value => value ).length === 1 && !switchButton1Ref.current.checked 
                || Object.values( selectedChart ).filter( value => value ).length > 1 
              ) 
            ) ? 
              switchButton1Ref.current.checked = !switchButton1Ref.current.checked 
              : null 
          }
        >Impressions</p>
        <TooltipInfos 
          content={ 'Affichage des URLs d’un site dans les SERP de Google' } 
          position={ 'right' }
          useBrowserTitle={ true }
        />
        { !isNullOrUndefined( currentValues.impressions) ? <p className='value'>{ currentValues.impressions }</p> : null }
      </div>
      <div className='chart-selector-button-container clicks'>
        <SwitchButton 
          onChange={ e => setSelectedCharts( { ...selectedChart, ...{ clicks: e.detail.value } } ) } 
          inverted 
          ref={ switchButton2Ref } 
          checked={ selectedChart.clicks }
        />
        <p 
          className='label' 
          onClick={ () => 
            (
              switchButton2Ref.current !== null 
              && (
                Object.values( selectedChart ).filter( value => value ).length === 1 && !switchButton2Ref.current.checked 
                || Object.values( selectedChart ).filter( value => value ).length > 1 
              ) 
            ) ? 
              switchButton2Ref.current.checked = !switchButton2Ref.current.checked 
              : null 
          }
        >Clics</p>
        <TooltipInfos 
          content={ 'Clics sur vos positions SEO Google' } 
          position={ 'right' }
          useBrowserTitle={ true }
        />
        { !isNullOrUndefined( currentValues.clicks) ? <p className='value'>{ currentValues.clicks }</p> : null }
      </div>
      <div className='chart-selector-button-container ctr'>
        <SwitchButton 
          ref={ switchButton3Ref } 
          onChange={ e => setSelectedCharts( { ...selectedChart, ...{ ctr: e.detail.value } } ) } 
          inverted 
          checked={ selectedChart.ctr }
        />
        <p 
          className='label' 
          onClick={ () => 
            (
              switchButton3Ref.current !== null 
              && (
                Object.values( selectedChart ).filter( value => value ).length === 1 && !switchButton3Ref.current.checked 
                || Object.values( selectedChart ).filter( value => value ).length > 1 
              ) 
            ) ?  
              switchButton3Ref.current.checked = !switchButton3Ref.current.checked 
              : null 
          }
        >CTR</p>
        <TooltipInfos 
          content={ 'Taux de clics sur les URLs de votre site' } 
          position={ 'right' }
          useBrowserTitle={ true }
        />
        { !isNullOrUndefined( currentValues.ctr) ? <p className='value'>{ currentValues.ctr }</p> : null }
      </div>
      <div className='chart-selector-button-container position'>
        <SwitchButton 
           ref={ switchButton4Ref } 
           onChange={ e => setSelectedCharts( { ...selectedChart, ...{ position: e.detail.value } } ) } 
           inverted 
           checked={ selectedChart.position }
          />
        <p 
          className='label' 
          onClick={ () => 
            (
              switchButton4Ref.current !== null 
              && (
                Object.values( selectedChart ).filter( value => value ).length === 1 && !switchButton4Ref.current.checked 
                || Object.values( selectedChart ).filter( value => value ).length > 1 
              ) 
            ) ?  
              switchButton4Ref.current.checked = !switchButton4Ref.current.checked 
              : null 
          }
        >Pos. moy.</p>
        <TooltipInfos 
          content={ 'Position moyenne du site sur la période sélectionnée' } 
          position={ 'right' }
          useBrowserTitle={ true }
        />        
        { !isNullOrUndefined( currentValues.position) ? <p className='value'>{ currentValues.position }</p> : null }
      </div>
    </div>
  )
}

export default ChartSelector;