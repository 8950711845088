/** Dependencies */
import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Input from 'smart-webcomponents-react/input';
import DropDownList from 'smart-webcomponents-react/dropdownlist';
import Button from 'smart-webcomponents-react/button';

/** Components */
import Loader from './../../Loader/Loader';

/** Helpers */
import { getInstanceDatas } from './../../../helpers/instance';
import { getPicto } from './../../../helpers/pictos';
import { isNullOrUndefined } from './../../../helpers/functions';

/** Services */
import { getItem } from './../../../services/LocaleStorage.js';

/** Object class */
import wsKeywords from './../../../helpers/webservice/wsKeywords.class';
import wsExpectedUrl from './../../../helpers/webservice/wsExpectedUrl.class';
import wsStatus from './../../../helpers/webservice/wsStatus.class';

/** SCSS */
import './AdminMenuContextualAddKeywords.scss';

const nbLine = 10;

function AdminMenuContextualAddKeywords( props )
{
  const {
    params,
    updateReloadTrigger,
    openCloseToggler
  } = props;

  /** useDispatch hook **/
	const dispatch = useDispatch();

  /** Get user id */
  const userId = getItem( 'userID' );

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID, device, location } = useParams();

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Init useState Hook */
  const [ keywordsList, setKeywordsList ] = useState( {} );
  const [ invalidKeywordsLabelList, setInvalidKeywordsLabelList ] = useState( {} );
  const [ invalidKeywordsCat1List, setInvalidKeywordsCat1List ] = useState( {} );
  const [ validationReady, setValidationReady ] = useState( false ); // use for waiting for checking is done before getting new keyword value

  /** Check for invalid keywords */
  const checkInvalidKeywords = ( keywordsList ) =>
  {
    // init invalid keyword label object
    const invalidKeywordsLabelList = {};

    /** Check for every same labels in form AND with followed keywords in params.keywords */
    const countLabel = {};
    Object.keys( keywordsList ).forEach( index => 
    {
      if( !isNullOrUndefined( keywordsList[index]?.label ) )
      {
        // set index to true if label exist in followed keywords list
        if(
          !isNullOrUndefined( params?.keywords )          
          && params.keywords.includes( keywordsList[index].label ) 
        )
          invalidKeywordsLabelList[index] = true;
  
        // get object with count item by label
        countLabel[keywordsList[index].label] = [
          ...( Array.isArray( countLabel[keywordsList[index].label] ) ? 
            countLabel[keywordsList[index].label] 
            : [] 
          ), 
          ...index 
        ]
      }
    });

    // set invalid keywords list with index with same labels
    Object.keys( countLabel ).filter( keyword => 
      countLabel[keyword].length > 1
    ).forEach( keyword => 
      countLabel[keyword].forEach( index => invalidKeywordsLabelList[index] = true )
    )

    // set invalid keywords label list
    setInvalidKeywordsLabelList( invalidKeywordsLabelList );

    // init invalid keyword cat1 object
    const invalidKeywordsCat1List = {};

    /** Check for all values required are filled */
    Object.keys( keywordsList ).forEach( index => 
    {
      if(
        (
          !isNullOrUndefined( keywordsList[index]?.label )
          || !isNullOrUndefined( keywordsList[index]?.expectedUrl )
          || !isNullOrUndefined( keywordsList[index]?.cat1 )
        ) && (
          isNullOrUndefined( keywordsList[index]?.label )
          || isNullOrUndefined( keywordsList[index]?.cat1 )
        )
      )
        invalidKeywordsCat1List[index] = true;
    });

    // set invalid keywords cat1 list
    setInvalidKeywordsCat1List( invalidKeywordsCat1List );

    // set validation ready to false
    setValidationReady( true );
  }

  const addKeywords = () => 
  {
    if(
      Object.keys( invalidKeywordsCat1List ).length === 0 
      && Object.keys( invalidKeywordsLabelList ).length === 0
      && Object.keys( keywordsList ).length > 0
      && validationReady
    ){
      // reset validation ready to false
      setValidationReady( false );

      // transform object keywords list to array
      let keywordsListArray = Object.values( keywordsList );

      keywordsListArray.forEach( keyword => 
      {
        if(
          !isNullOrUndefined( keyword?.label )
          && !isNullOrUndefined( keyword?.cat1 )
        ){
          // Add datas to kwCategories
          new wsKeywords(
            'admin-add-keyword',
            device,
            location + '|' + city,
            dispatch,
            clientID
          ).addKeywords( 
            [ keyword.label ],
            [ 
              keyword.cat1, 
              !isNullOrUndefined( keyword?.cat2 ) ? keyword.cat2 : 'N.C.',
              !isNullOrUndefined( keyword?.cat3 ) ? keyword.cat3 : 'N.C.',
            ], 
            'volume|serp|gsc'
          );
    
          // Add datas to kwStatus
          new wsStatus(
            'admin-add-keyword',
            device,
            location + '|' + city,
            dispatch,
            clientID
          ).addStatus( 
            [ keyword.label ],
            3
          );

          // Add datas to kwExpectedUrlUpdated
          if( !isNullOrUndefined( keyword?.expectedUrl ) )
            new wsExpectedUrl(
              'admin-add-keyword',
              device,
              location + '|' + city,
              dispatch,
              clientID
            ).addExpectedUrl( 
              [ keyword.label ],
              keyword.expectedUrl,
              'otherurl',
              userId
            );
        }  
      });
    }
  }

  useEffect( () => 
  { 
    if( Object.keys( keywordsList ).length > 0 )
      checkInvalidKeywords( keywordsList );

  }, [ keywordsList ]);

  return(
    <Fragment>

      {/* Loader */}
      <Loader 
        loaderID={ 'admin-add-keyword' }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        globalCallBack={ () => 
        {
          // update callback params
          if( typeof updateReloadTrigger === 'function' ) updateReloadTrigger();

          // close window
          if( typeof openCloseToggler === 'function' ) openCloseToggler();
        }}
      />

      {/* Add keywords content */}
      <div className="admin-add-keywords">
        <form>
          <table cellSpacing={16}>
            <thead>
              <tr>
                <td><div>{ getPicto( 'PenNib', { size: '1rem', weight: 'bold' } ) }Mot-clé</div></td>
                <td width='25%'><div>{ getPicto( 'LinkSimple', { size: '1rem', weight: 'bold' } ) }URL assignée</div></td>
                <td width='16%'><div>{ getPicto( 'ListChecks', { size: '1rem', weight: 'bold' } ) }Catégories</div></td>
                <td width='16%'><div>Sous catégories</div></td>
                <td width='16%'><div>Sous sous catégories</div></td>
              </tr>
            </thead>
            <tbody>
              {
                [...Array( nbLine )].map( ( line, index ) => 
                  <tr 
                    key={ index }                    
                  >
                    <td>
                      <Input        
                        className={ invalidKeywordsLabelList[index] ? 'invalid' : '' }       
                        onChanging={ e => 
                        {
                          // add label if not empty
                          if( e.detail.value.trim() !== '' ) 
                            setKeywordsList({ 
                              ...keywordsList, 
                              [index]: { 
                                ...keywordsList[index], 
                                label: e.detail.value.trim()
                              }
                            });

                          // remove index from keywords list if label is null
                          else {
                            const cloneKeywordsList = { ...keywordsList };
                            delete cloneKeywordsList[index];
                            setKeywordsList( cloneKeywordsList );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        id='admin-add-keywords-expected-url' 
                        dataSource={ !isNullOrUndefined( params?.gscUrls ) ? params.gscUrls : [] }
                        onChanging={ e => !isNullOrUndefined( e?.detail?.value ) ? 
                          setKeywordsList({ 
                            ...keywordsList, 
                            [index]: { 
                              ...keywordsList[index], 
                              expectedUrl: e.detail.value.trim() !== '' ? 
                                e.detail.value.trim() 
                                : null 
                            } 
                          })
                          : null
                        }
                      />
                    </td>
                    <td>
                      <DropDownList 
                        className={ invalidKeywordsCat1List[index] ? 'invalid' : '' }
                        dataSource={ !isNullOrUndefined( params?.categories ) ? 
                          Object.keys( params.categories ) 
                          : [] 
                        }
                        selectedValues={ !isNullOrUndefined( keywordsList[index]?.cat1 ) ? 
                          [ keywordsList[index].cat1 ] 
                          : [] 
                        }
                        onChange={ e =>
                          isNullOrUndefined( keywordsList[index]?.cat1 ) 
                          || ( 
                            !isNullOrUndefined( keywordsList[index]?.cat1 ) 
                            && keywordsList[index].cat1 !== e.detail.value 
                          ) ?
                            setKeywordsList({ 
                              ...keywordsList, 
                              [index]: { 
                                ...keywordsList[index], 
                                cat1: e.detail.value, 
                                cat2: null, 
                                cat3: null 
                              }
                            })
                          : null
                        }
                      />
                    </td>
                    <td>
                      <DropDownList 
                        dataSource={ !isNullOrUndefined( params?.categories ) && !isNullOrUndefined( keywordsList[index]?.cat1 ) ? 
                          Object.keys( params.categories[keywordsList[index].cat1].cat2 ) 
                          : [] 
                        }
                        selectedValues={ !isNullOrUndefined( keywordsList[index]?.cat2 ) ? 
                          [ keywordsList[index].cat2 ] 
                          : [] 
                        }
                        onChange={ e =>
                          isNullOrUndefined( keywordsList[index]?.cat2 ) 
                          || ( 
                            !isNullOrUndefined( keywordsList[index]?.cat2 ) 
                            && keywordsList[index].cat2 !== e.detail.value 
                          ) ?
                            setKeywordsList({ 
                              ...keywordsList, 
                              [index]: { 
                                ...keywordsList[index], 
                                cat2: e.detail.value, 
                                cat3: null 
                              }
                            })
                          : null
                        }
                        disabled={ isNullOrUndefined( keywordsList[index]?.cat1 ) }
                      />
                    </td>
                    <td>
                      <DropDownList 
                        dataSource={ !isNullOrUndefined( params?.categories ) && !isNullOrUndefined( keywordsList[index]?.cat2 ) ? 
                          Object.keys( params.categories[keywordsList[index].cat1].cat2[keywordsList[index].cat2].cat3 ) 
                          : [] 
                        }
                        selectedValues={ !isNullOrUndefined( keywordsList[index]?.cat3 ) ? 
                          [ keywordsList[index].cat3 ] 
                          : [] 
                        }
                        onChange={ e =>
                          isNullOrUndefined( keywordsList[index]?.cat3 ) 
                          || ( 
                            !isNullOrUndefined( keywordsList[index]?.cat3 ) 
                            && keywordsList[index].cat3 !== e.detail.value 
                          ) ?
                            setKeywordsList({ 
                              ...keywordsList, 
                              [index]: { 
                                ...keywordsList[index], 
                                cat3: e.detail.value 
                              }
                            })
                          : null
                        }
                        disabled={ isNullOrUndefined( keywordsList[index]?.cat2 ) }
                      />
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </form>
        <div className='confirm-container' id='admin-add-keywords-confirm-container'>
          <Button 
            className='flat empty'            
            onClick={ () => typeof openCloseToggler === 'function' ? openCloseToggler() : null }
          >Annuler</Button>

          <Button 
            className='flat fill' 
            onClick={ addKeywords }
          >Confirmer</Button>
        </div>
      </div>
    </Fragment>
  )
}

export default AdminMenuContextualAddKeywords;