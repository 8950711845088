/** Dependencies */
import { useEffect, useState } from 'react';

/** Components */
import AdminMenuContextualKeywords from './../AdminMenuContextual/AdminMenuContextualKeywords';

/** SCSS */
import './AdminMenu.scss'

function AdminMenu( props )
{
  const {
    indicators,
    params,
    callBackFct,
    updateReloadTrigger
  } = props;
  
  /** Init useState Hook */
  const [ currentAdminItem, setCurrentAdminItem ] = useState( 'keywords' );

  useEffect( () => 
  { 
    if( typeof callBackFct === 'function' )
      callBackFct( currentAdminItem );

  }, [ currentAdminItem ]);

  return(
    <div className='admin-menu-container'>
      <ul className='menu-container'>
        <li 
          className={ currentAdminItem === 'keywords' ? 'selected' : '' }
          onClick={ () => setCurrentAdminItem( 'keywords' ) }
        >
          <div className='label'>Mots-clés</div>
          <div className='count'>{ indicators.keywords }</div>
        </li>
        {/* <li
          className={ currentAdminItem === 'categories' ? 'selected' : '' }
          onClick={ () => setCurrentAdminItem( 'categories' ) }
        >
          <div className='label'>Catégories</div>
          <div className='count'>{ indicators.categories }</div>
        </li>
        <li
          className={ currentAdminItem === 'users' ? 'selected' : '' }
          onClick={ () => setCurrentAdminItem( 'users' ) }
        >
          <div className='label'>Utilisateurs</div>
          <div className='count'>{ indicators.users }</div>
        </li> */}
      </ul>
      {
        currentAdminItem === 'categories' ? 
          <div>Categories context menu</div>
        : currentAdminItem === 'users' ?
          <div>Users context menu</div>
        : 
          <AdminMenuContextualKeywords
            params={ params }
            updateReloadTrigger={ updateReloadTrigger }
          />
      }
    </div>
  )
}

export default AdminMenu;