/** Dependencies */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Input from 'smart-webcomponents-react/input';
import Button from 'smart-webcomponents-react/button';
import DropDownList from 'smart-webcomponents-react/dropdownlist';

/** Components */
import AreYouSure from './../../AreYouSure/AreYouSure';
import Loader from './../../Loader/Loader';

/** Helpers */
import { getInstanceDatas } from './../../../helpers/instance';
import { isNullOrUndefined } from './../../../helpers/functions';

/** Services */
import { getItem } from './../../../services/LocaleStorage.js';

/** Object class */
import wsKeywords from './../../../helpers/webservice/wsKeywords.class';
import wsExpectedUrl from './../../../helpers/webservice/wsExpectedUrl.class';

/** SCSS */
import './AdminMenuContextualMassActionsKeywords.scss';

function AdminMenuContextualMassActionsKeywords( props )
{
  const{
    params,
    updateReloadTrigger,
    openCloseToggler
  } = props;

  /** useDispatch hook **/
  const dispatch = useDispatch();

  /** Get user id */
  const userId = getItem( 'userID' );

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID, location, device } = useParams();

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  /** Init State */
  const [ areYouSureOpened, setAreYouSureOpened ] = useState( { opened: false, text: null } );
  const [ expectedUrl, setExpectedUrl ] = useState( null );
  const [ cat1, setCat1 ] = useState( null );
  const [ cat2, setCat2 ] = useState( null );
  const [ cat3, setCat3 ] = useState( null );

  // Define increase and decrease colors
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-blue').trim();

  const setCategories = ( cat1, cat2, cat3 ) =>
  {
    setCat1( cat1 );
    setCat2( cat2 );
    setCat3( cat3 );
  }

  const updateKeywords = () =>
  {
    if( !isNullOrUndefined( params?.selectedKeywords ) )
    {
      // update expected url
      if( !isNullOrUndefined( expectedUrl ) )
        new wsExpectedUrl(
          'admin-mass-actions-keywords',
          device,
          location + '|' + city,
          dispatch,
          clientID
        ).addExpectedUrl( 
          params.selectedKeywords,
          expectedUrl,
          'otherurl',
          userId
        );
  
      // update categories
      if( !isNullOrUndefined( cat1 ) )
        new wsKeywords(
          'admin-mass-actions-keywords',
          device,
          location + '|' + city,
          dispatch,
          clientID
        ).updateKeywords( 
          params.selectedKeywords,
          null,
          [ 
            cat1, 
            !isNullOrUndefined( cat2 ) ? cat2 : 'N.C.',
            !isNullOrUndefined( cat3 ) ? cat3 : 'N.C.',
          ]
        );
    }
  }

  const removeKeywords = () =>
  {
    if( !isNullOrUndefined( params?.selectedKeywords ) )
    {
      new wsKeywords(
        'admin-mass-actions-keywords',
        device,
        location + '|' + city,
        dispatch,
        clientID
      ).removeKeywords( 
        params.selectedKeywords
      );
    }
  };

  return(
    <div className='admin-mass-actions-keywords'>

      {/* Loader */}
      <Loader 
        loaderID={ 'admin-mass-actions-keywords' }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        globalCallBack={ () => 
        {
          // update callback params
          if( typeof updateReloadTrigger === 'function' ) updateReloadTrigger();

          // close window
          if( typeof openCloseToggler === 'function' ) openCloseToggler();
        }}
      />

      {/* Are you sure */}
      <AreYouSure
        text={ areYouSureOpened.text }
        cancelCallBackFct={ () => setAreYouSureOpened( { opened: false } ) }
        confirmCallBackFct={ () => typeof areYouSureOpened.function === 'function' ? areYouSureOpened.function.call() : void( 0 ) }
        isOpened={ areYouSureOpened.opened }
      />

      {/*  Content */}
      <h4>URL assignée</h4>
      <Input 
        id='admin-add-keywords-expected-url-mass-actions'
        dataSource={ !isNullOrUndefined( params?.gscUrls ) ? params.gscUrls : [] }
        onChange={ e => !isNullOrUndefined( e?.detail?.value ) ? setExpectedUrl( e.detail.value ) : void( 0 ) }
        onChanging={ e => !isNullOrUndefined( e?.detail?.value ) ? setExpectedUrl( e.detail.value ) :void( 0 ) }
      />
      <h4>Catégorie</h4>
      <DropDownList 
        dataSource={ !isNullOrUndefined( params.categories ) ? 
          Object.keys( params.categories ) 
          : [] 
        }
        selectedValues={ !isNullOrUndefined( cat1 ) ? [ cat1 ] : [] }
        onChange={ e => !isNullOrUndefined( e?.detail?.value ) ? setCategories( e.detail.value, null, null ) : void(0) }
      />
      <h4>Sous catégorie</h4>
      <DropDownList 
        dataSource={ !isNullOrUndefined( params?.categories[cat1]?.cat2 ) ? 
          Object.keys( params.categories[cat1].cat2 ) 
          : [] 
        }
        selectedValues={ !isNullOrUndefined( cat2 ) ? [ cat2 ] : [] }
        onChange={ e => !isNullOrUndefined( e?.detail?.value ) ? setCategories( cat1, e.detail.value, null ) : void(0) }
        disabled={ isNullOrUndefined( cat1 ) }
      />
      <h4>Sous sous catégorie</h4>
      <DropDownList 
        dataSource={ !isNullOrUndefined( params?.categories[cat1]?.cat2[cat2]?.cat3 ) ? 
          Object.keys( params.categories[cat1].cat2[cat2].cat3 ) 
          : [] 
        }
        selectedValues={ !isNullOrUndefined( cat3 ) ? [ cat3 ] : [] }
        onChange={ e => !isNullOrUndefined( e?.detail?.value ) ? setCategories( cat1, cat2, e.detail.value ) : void(0) }
        disabled={ isNullOrUndefined( cat2 ) }
      />

      {/* Confirm */}
      <div className='confirm-container' id='admin-add-keywords-confirm-container'>
        <Button 
          className='flat empty' 
          onClick={ openCloseToggler }
        >Annuler</Button>
        
        <Button 
          className='flat fill'
          onClick={ () => setAreYouSureOpened({ 
            opened: true, 
            text: 'Êtes vous sûr de vouloir modifier ces données ? Cette action est irréversible.',
            function: updateKeywords
          })}
        >Modifier</Button>

        <Button 
          className='flat fill'
          onClick={ () => setAreYouSureOpened({ 
            opened: true, 
            text: 'Êtes vous sûr de vouloir supprimer ces mots-clés ? Cette action est irréversible.',
            function: removeKeywords
          })}
        >Supprimer</Button>
      </div>
    </div>
  )
}

export default AdminMenuContextualMassActionsKeywords;