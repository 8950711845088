/** Dependencies */
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { onToggler } from './../../../reducers/togglers';

/** Helpers */
import { getPicto } from './../../../helpers/pictos';

/** SCSS */
import './MainMenu.scss';

function MainMenu()
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get navigation path
  const pathname = useLocation().pathname;

  // get instance infos from url
  const { clientID, location, device } = useParams();
  
  // set instances params for url path
  let instanceParams = '';
  if( 
    clientID !== undefined
    && clientID.trim() !== '' 
    && location !== undefined
    && location.trim() !== ''
    && device !== undefined
    && device.trim() !== '' 
  ){
    // selected instance params
    instanceParams = clientID + '/' + location + '/' + device;

  } else {

    // first instance params
    const devLoc = JSON.parse( userDatas.enabled_instances[0].devLoc )[0];
    instanceParams = userDatas.enabled_instances[0].nickname + '/' + devLoc.countryValue + '/' + devLoc.deviceValue;
  }
  
  return(
    <nav>
      <div id="main-menu" className="main-menu">
        <ul>
          <li>
            <Link 
              to="#"
              onClick={ () => dispatch( onToggler( 'burgerMenuIsOpened' ) ) }
            >{ getPicto( 'List', { size: 25, color: 'white' } ) }</Link>
          </li>
          <li>
            <Link 
              className={ pathname.startsWith( '/market/' ) ? 'active' : '' } 
              to={ 'market/' + instanceParams }
            >{ getPicto( 'Pulse', { size: 25, color: 'white' } ) }</Link>
          </li>
          <li>
            <Link 
              className={ pathname.startsWith( '/keywords/' ) ? 'active' : '' } 
              to={ 'keywords/' + instanceParams }
            >{ getPicto( 'Gauge', { size: 25, color: 'white' } ) }</Link>
          </li>
          <li>
            <Link 
              className={ pathname.startsWith( '/admin/keywords/' ) ? 'active' : '' } 
              to={ 'admin/keywords/' + instanceParams }
            >{ getPicto( 'Gear', { size: 25, color: 'white' } ) }</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
} 

export default MainMenu;