/** Dependencies */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { DropDownList } from 'smart-webcomponents-react/dropdownlist';

/** Helpers */
import { isNullOrUndefined } from './../../../helpers/functions';

/** SCSS */
import './GridCellDropDownMenu.scss';

function GridCellDropDownMenu( props )
{
  const {
    dataSource,
    currentCategory,
    placeHolder,
    callBackFct
  } = props;

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'dropdown-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );

  // render element
  root.render
  (    
    <DropDownList
      dataSource={ dataSource }
      placeholder={ placeHolder }
      disabled={ !( !isNullOrUndefined( dataSource ) && dataSource.length > 0 ) }
      selectedValues={ [ currentCategory ] }
      onChange={ e => callBackFct( e.detail.value ) }
      onOpen={ e  => e.stopPropagation() }
      onClose={ e  => e.stopPropagation() }
      _mouseWheelHandler={ () => {} }
    />
  );

  return rootElement;
} 

export default GridCellDropDownMenu;