/** Dependencies */
import moment from 'moment';

/** Helpers */
import { formatDate } from './dates';
import { getPicto } from './pictos';
import { 
  getMostRecentFromArrayObject,
  getSumFromArrayOfObject, 
  getAvgFromArrayOfObject, 
  getTopXFromArrayObject,
  sortArrayByObjectField,
  rewriteStringForCSS,
  isNullOrUndefined
} from './functions';

// Define increase and decrease colors
const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

/**
 * Return Array with values formatted for Chart component
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {String} periodType 
 * @returns Array
 */
export const formatDatasForChart = ( currentDatas, compareDatas, periodType = 'month' ) => 
{
  let datas = [];

  if( !isNullOrUndefined( currentDatas ) )
  {
    // currentDatas and CompareDatas are Arrays
    if( 
      Array.isArray( currentDatas ) 
      && currentDatas.length > 0
      && Array.isArray( compareDatas ) 
      && compareDatas.length > 0
    ){
      // refill datas if different length between current and compare
      const { refillCurrentDatas, refillCompareDatas } = refillDatas( currentDatas, compareDatas, periodType );
  
      // append values on datas array
      if( refillCurrentDatas.length === refillCompareDatas.length )
        refillCurrentDatas.forEach( ( values, index ) => 
        {
          datas = [...datas, { 
            xaxis: periodType === 'month' ?
              formatDate( values.x, 'YYYYMM', 'MMM YYYY' ) 
              : formatDate( values.x, 'YYYYMMDD', 'DD/MM/YY' ), 
            compareXaxisCaption: periodType === 'month' ?
              formatDate( refillCompareDatas[index].x, 'YYYYMM', 'MMM YYYY' ) 
              : formatDate( refillCompareDatas[index].x, 'YYYYMMDD', 'DD/MM/YY' ), 
            current: values.y, 
            compare: refillCompareDatas[index].y 
          }];
        });
  
    // currentDatas and CompareDatas are Object of arrays
    } else if ( !Array.isArray( currentDatas ) ) {

      // refill all values for all keys if compare datas not null
      if( !isNullOrUndefined( compareDatas ) )
      {
        Object.keys( currentDatas ).forEach( key => 
        {
          // get refill datas
          const { refillCurrentDatas, refillCompareDatas } = refillDatas( 
            currentDatas[key], 
            compareDatas[key] !== undefined ? 
              compareDatas[key] : 
              [], 
            periodType 
          );
  
          // update current and compare datas
          currentDatas[key] = refillCurrentDatas;
          compareDatas[key] = refillCompareDatas;
        });
      }
      
      // get all dates of period from first key value
      const currentPeriod = currentDatas[Object.keys( currentDatas )[0]].map( data => data.x );
      const comparePeriod = !isNullOrUndefined( compareDatas ) ? compareDatas[Object.keys( compareDatas )[0]].map( data => data.x ) : null;
  
      // get values for each period
      currentPeriod.forEach( ( date, index ) => 
      {
        // format date to xaxis
        let values = { 
          xaxis: periodType === 'month' ?
            formatDate( date, 'YYYYMM', 'MMM YYYY' ) 
            : formatDate( date, 'YYYYMMDD', 'DD/MM/YY' )
        };

        // add compareXaxisCaption
        if( !isNullOrUndefined( comparePeriod ) )
          values.compareXaxisCaption = periodType === 'month' ?
            formatDate( comparePeriod[index], 'YYYYMM', 'MMM YYYY' ) 
            : formatDate( comparePeriod[index], 'YYYYMMDD', 'DD/MM/YY' )

        // get values for each key for current datas
        Object.keys( currentDatas ).forEach( key =>
        {
          values[ 'current-' + key ] = !isNullOrUndefined( currentDatas[key][index] ) ? currentDatas[key][index].y : null;

          if( !isNullOrUndefined( compareDatas ) )
            values[ 'compare-' + key ] = !isNullOrUndefined( compareDatas[key][index] ) ? compareDatas[key][index].y : null;
        });
  
        // append values on datas array
        datas = [ ...datas, values ];
      });
    }
  }
  
  return datas;
}

/**
 * Return Array with null values in current or compared array to be equal with entries
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {String} periodType 
 * @returns Array
 */
const refillDatas = ( currentDatas, compareDatas, periodType ) => 
{
  // clone datas arrays
  const refillCurrentDatas = [ ...currentDatas ];
  const refillCompareDatas = [ ...compareDatas ];

  // function to add month or week value
  const increaseDate = ( date, periodType ) => periodType === 'month' ? 
    parseInt( moment( date, 'YYYYMMDD' ).add( 1, 'months' ).format( 'YYYYMMDD' ) ) 
    : parseInt( moment( date, 'YYYYMMDD' ).add( 1, 'weeks' ).format( 'YYYYMMDD' ) );

  if( 
    Array.isArray( currentDatas ) 
    && Array.isArray( compareDatas )
    && currentDatas.length > 0
    && compareDatas.length > 0
    && currentDatas.length !== compareDatas.length
  ){
    // get diff length between current and compare datas array
    const diffLenght = currentDatas.length - compareDatas.length;

    // get last date value 
    let lastDate = ( diffLenght > 0 ) ? compareDatas.slice( -1 )[0].x : currentDatas.slice( -1 )[0].x;

    // add missed dates
    for ( let i = 0 ; i < Math.abs( diffLenght ); i++ )
    {
      // get last date
      lastDate  = increaseDate( lastDate, periodType );

      // push value if current datas array longest
      if( diffLenght > 0 )
        refillCompareDatas.push( { x: lastDate, y: null } );
      
      // push value if compare datas array longest
      else
        refillCurrentDatas.push( { x: lastDate, y: null } );
    }    
  }

  return { refillCurrentDatas: refillCurrentDatas, refillCompareDatas: refillCompareDatas };
}

/**
 * Return data object for InsightCompare component after getting last value
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {Boolean} flip 
 * @returns Array
 */
export const aggregateDatasForInsightCompareMostRecent = ( currentDatas, compareDatas, flip = false ) =>
{
  return formatDatasforInsightCompare(
    { 
      currentValues: getMostRecentFromArrayObject( currentDatas, 'x', 'y' ), 
      compareValues: getMostRecentFromArrayObject( compareDatas, 'x', 'y' )
    },
    flip
  );
}

/**
 * Return data object for InsightCompare component after sum processing
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {Boolean} flip 
 * @returns Array
 */
export const aggregateDatasForInsightCompareSum = ( currentDatas, compareDatas, flip = false ) =>
{
  return formatDatasforInsightCompare(
    { 
      currentValues: getSumFromArrayOfObject( currentDatas, 'y' ), 
      compareValues: getSumFromArrayOfObject( compareDatas, 'y' )    
    },
    flip
  );
}

/**
 * Return data object for InsightCompare component after avg processing
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {Boolean} flip 
 * @returns Array
 */
export const aggregateDatasForInsightCompareAvg = ( currentDatas, compareDatas, flip = false ) =>
{
  return formatDatasforInsightCompare(
    { 
      currentValues: getAvgFromArrayOfObject( currentDatas, 'y' ), 
      compareValues: getAvgFromArrayOfObject( compareDatas, 'y' ) 
    }, 
    flip
  );
}

/**
 * Return datas object with current and compare values 
 * after adding insights values to display InsightCompare component
 * @param {Object} datas 
 * @param {Boolean} flip 
 * @returns Object
 */
const formatDatasforInsightCompare = ( datas, flip = false ) =>
{
  return datas.insight = {
    current: datas.currentValues,
    compare: datas.compareValues,
    variation: (
      datas.compareValues !== null && datas.currentValues !== null
      ) ? 
      flip ? 
        datas.compareValues - datas.currentValues 
        : datas.currentValues - datas.compareValues
      : null
  }
}

/**
 * Return Array with value / classname with predefined colors
 * from data object for grp snippets list
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightGrpSnippetsList = ( currentDatas ) => 
{
  // get values
  const values = Object.keys( currentDatas ).map( key => 
  {
    // get class 
    let className = null;
    switch ( key ) {
      case 'Ads': className = 'orange'; break;
      case 'Snippets': className = 'yellow'; break;
      case 'SEO': className = 'blue'; break;    
      default:
        break;
    }

    return {
      label: key,
      value: getAvgFromArrayOfObject( currentDatas[key], 'y' ),
      className: className
    }
  });

  // return with order
  return [ 
    values.filter( elem => elem.label === 'SEO' )[0],
    values.filter( elem => elem.label === 'Ads' )[0], 
    values.filter( elem => elem.label === 'Snippets' )[0]    
  ];
}

/**
 * Call aggregated function with sum
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightTypeSnippetsListSum = currentDatas => 
  aggregateDatasForInsightTypeSnippetsList( currentDatas, 'sum' );

/**
 * Call aggregated function with avg
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightTypeSnippetsListAvg = currentDatas => 
  aggregateDatasForInsightTypeSnippetsList( currentDatas, 'avg' );

/**
 * Call aggregated function with most recently
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightTypeSnippetsListMostRecent = currentDatas => 
  aggregateDatasForInsightTypeSnippetsList( currentDatas, 'mostrecent' );

/**
 * Return Array with value / classname with gradient / picto 
 * from datas object for type snippets list
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightTypeSnippetsList = ( currentDatas, aggregateType ) => 
{
  // get values
  let values = Object.keys( currentDatas ).map( key => 
  {
    return {
      label: key,
      value: 
        aggregateType === 'avg' ? 
          getAvgFromArrayOfObject( currentDatas[key], 'y' )
        : aggregateType === 'sum' ? 
          getSumFromArrayOfObject( currentDatas[key], 'y' )
        : 
          getMostRecentFromArrayObject( currentDatas[key], 'x', 'y', true )
    }
  })
  
  // sort values
  values = sortArrayByObjectField( values, 'value', 'number', 'desc' );
  
  // add className by order AND picto by label
  values.forEach( value => 
  {
    // set className
    value.className = 'snippet-' + rewriteStringForCSS( value.label );

    // set picto
    value.picto = getPicto( value.label, { size: '1rem', weight: 'bold' } );
  });

  return values;
}

/**
 * Return series for Chart component with gradient color for Top 5
 * @param {Array} datas 
 * @returns Array
 */
export const createCustomSeriesOrderByValues = ( datas ) => 
{
  let series = [];

  if( Array.isArray( datas ) )
  {
    let cloneDatas = structuredClone( datas );
  
    // remove xaxis name if exist
    cloneDatas.forEach( elem => elem?.xaxis !== undefined ? delete elem.xaxis : elem );

    // get sum of each value
    const topValues = getTopXFromArrayObject( cloneDatas, 5 );
  
    // set series with top values
    series = topValues.map( ( value, index ) => ({ 
      dataField: value.key,
      opacity: ( 0.75 - ( index * 0.15 ) ).toFixed( 2 ),
      fillColor: blueColor,
      fillColorSymbolSelected: blueColor,
      lineColor: blueColor
    })); 
  }

  return series;
}

/**
 * Return series for Chart component with associated color with label
 * @param {Array} datas 
 * @returns Array
 */
export const createCustomSeriesForSnippets = ( datas, options = {} ) => 
{
  let series = [];

  if( Array.isArray( datas ) )
  {
    let cloneDatas = structuredClone( datas );

    // remove xaxis name if exist
    cloneDatas.forEach( elem => elem?.xaxis !== undefined ? delete elem.xaxis : elem );

    // remove compareXaxisCaption name if exist
    cloneDatas.forEach( elem => elem?.compareXaxisCaption !== undefined ? delete elem.compareXaxisCaption : elem );

    // get labels of each valueto use them for datafields
    const dataFields = [...new Set(
      cloneDatas.reduce(
        ( accumulator, currentValue ) => [ ...accumulator, Object.keys( currentValue ) ],
        [],
      ).flat()
    )];

    // set series with top values
    series = dataFields.map( value => ({ 
      ...options,
      dataField: value,
      opacity: value.indexOf( 'current' ) > -1 ? 1 : 0.8,
      dashStyle: value.indexOf( 'current' ) > -1 ? '' : '4,4',
      lineWidth: value.indexOf( 'current' ) > -1 ? 2 : 1.5,
      fillColor: getComputedStyle( document.documentElement ).getPropertyValue( '--snippet-color-' + rewriteStringForCSS( value.replace(/(current|compare)-/g, '' ) ) ).trim(),
      fillColorSymbolSelected: getComputedStyle( document.documentElement ).getPropertyValue( '--snippet-color-' + rewriteStringForCSS( value.replace(/(current|compare)-/g, '' ) ) ).trim(),
      lineColor: getComputedStyle( document.documentElement ).getPropertyValue( '--snippet-color-' + rewriteStringForCSS( value.replace(/(current|compare)-/g, '' ) ) ).trim()
    })); 
  }

  return series;
}

/**
 * Return array of predefined ranges values for dropdown menu in filters
 * @param {Array} ranges [ '0|10', '10|100', '100|1000', '1000|10000', '10000|100000', '100000|more' ...] 
 * @param {Integer} maxValue 
 * @returns 
 */
export const getPredefinedFilterRangesValues = ( ranges, minValue, maxValue ) => 
{
  let predefinedValues = [];

  // format min value
  const minValueFormat = Intl.NumberFormat( 'fr-FR', { notation: 'compact', roundingMode: 'ceil' } ).format( minValue );

  // format max value
  const maxValueFormat = Intl.NumberFormat( 'fr-FR', { notation: 'compact', roundingMode: 'ceil' } ).format( maxValue );

  ranges.forEach( range => 
  { 
    // split range value
    const minRangeValue = range.split( '|' )[0];
    const minRangeValueFormat = Intl.NumberFormat( 'fr-FR', { notation: 'compact', roundingMode: 'ceil' } ).format( range.split( '|' )[0] );
    const maxRangeValue = range.split( '|' )[1];
    const maxRangeValueFormat = Intl.NumberFormat( 'fr-FR', { notation: 'compact', roundingMode: 'ceil' } ).format( range.split( '|' )[1] );

    if( minValue <= maxRangeValue && maxValue >= minRangeValue && maxRangeValue !== 'more' )
      predefinedValues.push( { 
        label: ( minValue > minRangeValue ? minValueFormat : minRangeValueFormat ) + ' - ' + ( maxValue < maxRangeValue ? maxValueFormat : maxRangeValueFormat ), 
        value: ( minValue > minRangeValue ? minValue : minRangeValue ) + '|' + ( maxValue < maxRangeValue ? maxValue : maxRangeValue )
      });
    else if( maxValue > minRangeValue && maxRangeValue === 'more' )
      predefinedValues.push( { 
        label: minRangeValueFormat + '+', 
        value: minRangeValue + '|' + maxValue
      });
  });

  return predefinedValues;
}

/**
 * Return value of field wich could be automatic or updated
 * @param {Object} datas 
 * @param {String} fieldValue 
 * @returns String
 */
export const getUpdateOrAutoValue = ( datas, fieldName ) => 
{
  const updateFieldName = 'updated_' + fieldName;
  const autoFieldName = 'automatic_' + fieldName;

  return (
    !isNullOrUndefined( datas ) && !isNullOrUndefined( datas[updateFieldName] ) ?
      datas[updateFieldName]
    : !isNullOrUndefined( datas ) && !isNullOrUndefined( datas[autoFieldName] ) ?
      datas[autoFieldName]
    : null
  );
}