/** Dependencies */
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

/** Redux */
import store from './../../../store';

/** Components */
import WindowButton from './../../WindowButton/WindowButton';
import AreYouSure from './../../AreYouSure/AreYouSure';

/** Helpers */
import { getPicto } from './../../../helpers/pictos';

/** SCSS */
import './GridCellRemove.scss';

function GridCellRemove( props )
{
  const {
    index,
    pageSize,
    keyword,
    confirmCallBackFct
  } = props;

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'remove-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );

  // render element
  root.render
  (    
    <Provider store={ store }>
      <WindowButton
        id={ 'remove-keyword-' + index }
        className="remove-keyword"
        windowContent={
          <AreYouSure 
            text={ 'Êtes vous sûr de vouloir supprimer le mot-clé "' + keyword + '" ?' }
            confirmCallBackFct={ confirmCallBackFct }
            isOpened={ true }
          />
        }
        activeEffect="horizontalFlip"
        buttonContent={ getPicto( 'Trash', { size: '1.4rem' } ) }
        mouseEventType="click"
        positionClassName={ ( ( index + 1 ) / pageSize ) % 1 > 0.75 || Number.isInteger( ( index + 1 ) / pageSize ) ? 'bottom' : 'top' }
      />
    </Provider>
  );

  return rootElement;
} 

export default GridCellRemove;