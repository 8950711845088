/** Dependencies */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

/** Components */
import AdminMenu from './../../components/Menus/AdminMenu/AdminMenu';
import AdminContentKeywords from './../../components/AdminContent/Keywords/Keywords';
import AdminContentCategories from './../../components/AdminContent/Categories/Categories';
import AdminContentUsers from './../../components/AdminContent/Users/Users';

/** Helpers */
import { isNullOrUndefined } from './../../helpers/functions';
import { getInstanceDatas } from './../../helpers/instance';

/** SCSS */
import './Admin.scss';

function Admin()
{
  /** Get instance infos from url */
  const { clientID } = useParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Get instance datas from user datas */
  const instanceDatas = getInstanceDatas( clientID, userDatas );

  /** Init useState Hook */
  const [ currentAdminItem, setCurrentAdminItem ] = useState( 'keywords' );
  const [ params, setParams ] = useState( null );
  const [ reloadTrigger, setReloadTrigger ] = useState( null );

  return(
    <div className='admin-keywords'>
      <div className='admin-box1'>
        <div className='identity'>
          { /* Picture */
            !isNullOrUndefined( instanceDatas?.picture ) && !isNullOrUndefined( instanceDatas?.label ) ? 
              <img src={ instanceDatas.picture } alt={ instanceDatas.label } title={ instanceDatas.label } className="picture" />
              : null
          }
          
          { /* Instance Label */
            !isNullOrUndefined( instanceDatas?.label ) ? 
              <h2>{ instanceDatas.label }</h2>
              : null
          }
        </div>
      </div>
      <div className='admin-box2'>
        <AdminMenu
          indicators={{
            keywords: !isNullOrUndefined( params?.keywords ) ? params.keywords.length + ' / ' + params.keywords.length : '- / -',
            categories: '87',
            users: '8',
          }}
          params={ params }
          callBackFct={ results => setCurrentAdminItem( results ) }
          updateReloadTrigger={ () => setReloadTrigger( Math.ceil( Math.random() * 100 ) ) }
        />
      </div>
      <div className='admin-box3'>
        {
          currentAdminItem === 'categories' ? 
            <AdminContentCategories />
          : currentAdminItem === 'users' ?
            <AdminContentUsers />
          : 
            <AdminContentKeywords
              callBackFct={ results => setParams( results ) }
              reloadTrigger={ reloadTrigger }
            />
          }
      </div>
    </div>
  )
}

export default Admin;