/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsKeywords
{
  constructor(
    loaderID,
    devices,
    locations,
    dispatch,
    selectedInstance
  ){
    this.devices = devices;
    this.locations = locations;
    this.loaderID = loaderID;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;
  }

  addKeywords = ( 
    keywords, 
    categories, 
    extractSources,
    callBackFct = null
  ) => 
  {
    this.exec(
      keywords, 
      null,
      categories, 
      'add',
      extractSources,
      callBackFct
    );
  }
  
  updateKeywords = (
    keywords, 
    newLabel = null,
    categories = null, 
    extractSources = null,
    callBackFct = null
  ) => 
  {
    this.exec(
      keywords, 
      newLabel,
      categories, 
      'update',
      extractSources,
      callBackFct
    );
  }

  updateKeywordLabel = (
    keywords, 
    newLabel,
    callBackFct = null
  ) => 
  {
    this.exec(
      keywords, 
      newLabel,
      null, 
      'update-label',
      null,
      callBackFct
    );
  }
  
  /** Remove keyword from kwCategories */
  delKeywords = (
    keywords,
    callBackFct = null
  ) => 
  {
    this.exec(
      keywords, 
      null,
      null,
      'delete',
      null,
      callBackFct
    );
  }

  /** Update kwCategories with removedDate and extractSource='' */
  removeKeywords = (
    keywords,
    callBackFct = null
  ) => 
  {
    this.exec(
      keywords, 
      null,
      null,
      'remove',
      '',
      callBackFct
    );
  }
  
  exec = (
    keywords, 
    newLabel,
    categories, 
    actionType,
    extractSources,
    callBackFct = null
  ) => 
  {
    callWebservice( 
      this.loaderID,
      actionType + '-keywords',
      actionType + '-keywords',
      this.dispatch,
      this.selectedInstance,
      {
        keywords: keywords, 
        label: newLabel,
        categories: categories,
        device: Array.isArray( this.devices ) && this.devices[0] ? this.devices[0] : this.devices,
        devices: Array.isArray( this.devices ) ? this.devices : [ this.devices ],
        location: Array.isArray( this.locations ) && this.locations[0] ? this.locations[0] : this.locations,
        locations: Array.isArray( this.locations ) ? this.locations : [ this.locations ],
        extractSources: extractSources
      },
      callBackFct !== null ? {
        function: callBackFct
      } : null
    );
  }
}