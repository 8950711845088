/** Dependencies */
import { Fragment, useState, useEffect, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button } from 'smart-webcomponents-react/button';
import { ButtonGroup } from 'smart-webcomponents-react/buttongroup';
import { useNavigate, useParams } from "react-router-dom";

/** Components */
import CompareValuesChart from './../../components/Widgets/MarketWidgets/CompareValuesChart';
import InstanceInfos from './../../components/Instances/InstanceInfos';
import PeriodSelector from './../../components/PeriodSelector/PeriodSelector';
import PeriodCalendar from './../../components/PeriodCalendar/PeriodCalendar';
import RangeGranularity from './../../components/RangeGranularity/RangeGranularity';
import GridCoreMarket from './../../components/Grid/Core/Market/GridCoreMarket';
import FiltersWindow from './../../components/FiltersWindow/FiltersWindow';
import KeywordsGroupsWindow from './../../components/KeywordsGroupsWindow/KeywordsGroupsWindow';
import WindowButton from './../../components/WindowButton/WindowButton';
import DisplayModeSelector from './../../components/DisplayModeSelector/DisplayModeSelector';
import FiltersRemovers from './../../components/FiltersRemovers/FiltersRemovers';

/** Helpers */
import { getPicto } from './../../helpers/pictos';
import { getInstanceDatas } from './../../helpers/instance';
import { 
  filterTopXFromArrayObject, 
  sortArrayByObjectField,
  isNullOrUndefined,
  isValidJSON
} from './../../helpers/functions';

import { 
  aggregateDatasForInsightCompareSum, 
  aggregateDatasForInsightCompareMostRecent,
  aggregateDatasForInsightGrpSnippetsList,
  aggregateDatasForInsightTypeSnippetsListAvg,
  createCustomSeriesForSnippets 
} from './../../helpers/datas';

/** Services */
import { getItem } from './../../services/LocaleStorage.js';

/** SCSS */
import '@phosphor-icons/web/regular';
import './InstanceMarket.scss';

function InstanceMarket()
{
  /** Instance useNavigate */
  const navigate = useNavigate();

  /** Init State */
  const [ currentPeriod, setCurrentPeriod ] = useState( null );
  const [ comparePeriod, setComparePeriod ] = useState( null );
  const [ filters, setFilters ] = useState( null );
  const [ mcpPositionChartType, setMcpPositionChartType ] = useState( 'line' );
  const [ serpCompositionChartType, setSerpCompositionChartType ] = useState( 'grouped' );
  const [ serpRateChartType, setSerpRateChartType ] = useState( 'grouped' );
  const [ serpHitsChartType, setSerpHitsChartType ] = useState( 'grouped' );
  const [ gridParams, setGridParams ] = useState( null );
  const [ gridKeywords, setGridKeywords ] = useState( 'followedKeywords' );
  const [ gridSelectedKeywords, setGridSelectedKeywords ] = useState( [] );
  const [ gridSelectedKeywordsGroups, setGridSelectedKeywordsGroups ] = useState( [] );
  const [ chartSelectedKeywords, setChartSelectedKeywords ] = useState( [] );
  const [ gridLabels, setGridLabels ] = useState( [] );
  const [ gridKeywordsGroups, setGridKeywordsGroups ] = useState( [] );
  const [ gridUrls, setGridUrls ] = useState( [] );
  const [ minMaxValues, setMinMaxValues ] = useState( null );
  const [ displayMode, setDisplayMode ] = useState( 'chart-grid' );
  const [ box2Width, setBox2Width ] = useState( null );
  const [ mouseOverSerpChartDetails, setMouseOverSerpChartDetails ] = useState( null );
  const [ reloadTrigger, setReloadTrigger ] = useState( null );

  /** Define Refs */
  const box2Ref = useRef( null );

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** UseSearchParams Hook */ 
  const [ searchParams ] = useSearchParams();

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();
  const yellowColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-yellow' ).trim();
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();
  const orangeColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-orange' ).trim();

  /** Get instance infos from url */
  const { clientID, device, location } = useParams();

  /** Get user id */
  const userId = getItem( 'userID' );

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  /** Redirect to first instance if not defined */
  useEffect( () => 
  {
    if( clientID === undefined )
    {
      // get nickname of first instance after sort by label
      const firstNickname = sortArrayByObjectField( structuredClone( userDatas.enabled_instances ), 'label' )[0].nickname;

      // get first instance datas
      const firstInstanceDatas =  getInstanceDatas( firstNickname, userDatas );

      // set redirect url
      let url = '/market/' + firstNickname;
      url += '/' + firstInstanceDatas.devLoc[0].countryValue;
      url += '/' + firstInstanceDatas.devLoc[0].deviceValue;

      // redirect to url
      navigate( url );
    }
    
  }, [] );
    
  /** Update grid datas after loading */
  useEffect( () =>
  {
    if( !isNullOrUndefined( gridParams?.keywords ) ) setGridKeywords( gridParams.keywords );
    if( !isNullOrUndefined( gridParams?.selectedKeywords ) ) setGridSelectedKeywords( gridParams.selectedKeywords );
    if( !isNullOrUndefined( gridParams?.labels ) ) setGridLabels( gridParams.labels );
    if( !isNullOrUndefined( gridParams?.keywordsGroups ) ) setGridKeywordsGroups( gridParams.keywordsGroups );
    if( !isNullOrUndefined( gridParams?.urls ) ) setGridUrls( gridParams.urls );
    if( !isNullOrUndefined( gridParams?.currentPeriod ) ) setCurrentPeriod( gridParams.currentPeriod );
    if( !isNullOrUndefined( gridParams?.comparePeriod ) ) setComparePeriod( gridParams.comparePeriod );
    if( !isNullOrUndefined( gridParams?.minMaxValues ) ) setMinMaxValues( gridParams.minMaxValues );
    
  }, [ gridParams ] );

  /** Set url search params */
  useEffect( () => 
  {
    if( searchParams.get( 'currentPeriod' ) !== null )
      setCurrentPeriod( searchParams.get( 'currentPeriod' ) );
    else
      setCurrentPeriod( '12M' );

    if( searchParams.get( 'comparePeriod' ) !== null )
      setComparePeriod( searchParams.get( 'comparePeriod' ) );
    else
      setComparePeriod( 'N-1' );

    if( 
      searchParams.get( 'filters' ) !== null 
      && isValidJSON( searchParams.get( 'filters' ) ) 
    )
      setFilters( JSON.parse( searchParams.get( 'filters' ) ) );
    else
      setFilters( null );
      
  }, [ searchParams ]);

  /** Add event listener on resize screen */
  useEffect( () => 
  {
    if( box2Ref?.current !== null && box2Ref?.current !== undefined )
    {
      const updateFiltersWindowWidth = () => setBox2Width( box2Ref.current.offsetWidth );

      // init width
      updateFiltersWindowWidth();

      // add event listener on screen resize
      window.addEventListener( 'resize', updateFiltersWindowWidth );
      return () => {
        window.removeEventListener( 'resize', updateFiltersWindowWidth );
      };
    }

  }, [ box2Ref.current ]);

  /** Reset grid selected keywords groups when keyword is selected manualy */
  useEffect( () => 
  {
    if( gridSelectedKeywords.length > 0 )
      setGridSelectedKeywordsGroups( [] );

    else if( chartSelectedKeywords.length > 0 )
      setChartSelectedKeywords( [] );

  }, [ gridSelectedKeywords ]);

  return(
    <div className = { displayMode !== 'chart-grid' ? 'instance-market ' + displayMode : 'instance-market' }>
      { clientID !== undefined ?
        <Fragment>

          <div className='box1'>
            <InstanceInfos />

            <div className='period-infos-wrapper'>
              <PeriodSelector />
              <PeriodCalendar />
            </div>
          </div>

          <div className='box2' ref={ box2Ref }>
            <div className='header'>            
              <div className='header-left-container'>
                <h3>Marché</h3>
                  <DisplayModeSelector
                    callBackFunction = { setDisplayMode }
                    currentValue = { displayMode }
                  /> 
              </div>
              <div className='header-right-container'>

                {/* Filters removers */}
                <FiltersRemovers />
                
                {/* Filters / Keywords groups => Button */}

                {/* Filters button */}
                <WindowButton 
                  id="filters"
                  className="filters"
                  activeEffect="horizontalFlip"
                  buttonContent={ getPicto( 'Funnel', { size: '1.5rem', fill: grey2Color } ) }
                  windowContent={ 
                    <FiltersWindow 
                      minMaxValues={ minMaxValues } 
                      autoCompletedLabelsValues={ gridLabels } 
                      autoCompletedUrlsValues={ gridUrls }                             
                      dropDownMenuKeywordsGroups={ gridKeywordsGroups } 
                    /> 
                  }
                  indicator={ filters !== null ? 
                    filters.map( filter => Object.keys( filter.values ).length ).reduce( ( a, v ) => a + v, 0 ) 
                    : null 
                  }
                  width={ box2Width }
                />

                {/* Keywords groups button  */}
                <WindowButton 
                  id="keywords-groups"
                  className="keywords-groups"
                  activeEffect="horizontalFlip"
                  buttonContent={ getPicto( 'Bookmarks', { size: '1.5rem', fill: grey2Color } ) }
                  windowContent={ 
                    <KeywordsGroupsWindow 
                      currentSelectedKeywords={ gridSelectedKeywords }
                      callBackFunction={ values => setGridSelectedKeywordsGroups( values ) }
                    />
                  }                        
                />
                
                {/* <Button className='flat circle'>{ getPicto( 'FloppyDisk', { size: '1.2rem' } ) }</Button>              
                <Button className='flat circle'>{ getPicto( 'DotsThreeVertical', { size: '1.6rem', weight: 'bold' } ) }</Button> */}
                
              </div>
            </div>

            {/* Next page button */}
            <Button 
              className='flat fill next-page'
              disabled={ gridSelectedKeywords.length === 0 ? true : false }
            >
              { getPicto( 'ArrowPlainDown', { size: '1.5rem', viewBox: '0 0 24 24', transform: 'rotate( -90 )', fill: 'white' } ) }
              <p className='checkboxes-sum'>{ gridSelectedKeywords.length }</p>
            </Button>

          </div>
          
          <div className='box3'>   
            
            {/* box3 header */}
            <div className='header'>
              <div className='left-col'>&nbsp;</div>
              <RangeGranularity />
              <div className='right-col'>
                <Button 
                  disabled={ 
                    ( 
                      gridSelectedKeywords.length === chartSelectedKeywords.length 
                      && gridSelectedKeywords.filter( elem => !chartSelectedKeywords.includes( elem ) ).length === 0 ? 
                        true : false
                    )
                  }
                  className='flat circle'
                  onClick={ () => gridSelectedKeywords.length > 0 ? setChartSelectedKeywords( gridSelectedKeywords ) : setChartSelectedKeywords( [] ) }
                >{ getPicto( 'ArrowsClockwise', { size: '1.6rem', color: grey2Color } ) }</Button>                  
              </div>           
            </div>      
            
            {/* Clicks */}
            <div className='widget-wrapper'>
              <CompareValuesChart
                id="mcp-clicks"
                title="Nombre de clics"
                pictoTitle={ getPicto( 'Mouse', { size: "1rem", weight:'bold', color: grey2Color } ) }
                typeChart="column"
                serviceName="gsc-trend"
                params={{
                  currentPeriod: currentPeriod,
                  comparePeriod: comparePeriod,
                  webservice: { 
                    where: { 
                      keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords
                    }
                  }
                }}
                formatResultFct={ results => results.clicks }
                aggregateDatasForInsight={ aggregateDatasForInsightCompareSum }
                xAxisLabelVisible={ false }
                tooltipInfosContent={ 'Clics sur vos positions SEO Google' }
                tooltipInfosPosition={ 'right' }
              />
            </div>

            {/* Average positions */}
            <div className='widget-wrapper'>
              { mcpPositionChartType === 'line' ?
                <CompareValuesChart
                  id="mcp-avg-position"
                  title="Position SERP"
                  pictoTitle={ getPicto( 'ArrowsVertical', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="line"
                  serviceName="serp-trend-avgpos"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  aggregateDatasForInsight={ aggregateDatasForInsightCompareMostRecent }
                  flip={{ valueAxis: true }}
                  xAxisLabelVisible={ false }
                  tooltipInfosContent={ 'Position moyenne calculée sur le dernier mois de la période sélectionnée' }
                  tooltipInfosPosition={ 'right' }
                />
                :
                <CompareValuesChart
                  id="mcp-top-position"
                  title="Répartition des positions"
                  pictoTitle={ getPicto( 'ArrowsVertical', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-range-positions"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: 'none',
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={{
                    current: [
                      { 
                        dataField: 'current-top21andmore',
                        fillColor: grey2Color,
                        lineColor: grey2Color,
                        lineWidth: 0,
                        opacity: 0.3
                      },{ 
                        dataField: 'current-top1120',
                        fillColor: grey2Color,
                        lineColor: grey2Color,
                        lineWidth: 0,
                        opacity: 0.6
                      },{ 
                        dataField: 'current-top410',
                        fillColor: grey2Color,
                        lineColor: grey2Color,
                        lineWidth: 0,
                      },{ 
                        dataField: 'current-top3',
                        fillColor: yellowColor,
                        lineColor: yellowColor,
                        lineWidth: 0,
                      }
                    ]
                  }}
                  formatResultFct={ results => results.me }
                  caption={['Top 3', '4 à 10', '11 à 20', '21 et plus']}
                  xAxisLabelVisible={ false }
                />
              }
              <div className='button-group-graph-select'>
                <ButtonGroup
                  className='column empty'
                  dataSource={[
                    { 
                      label: '<i class="ph ph-chart-line" style="font-size: 1.5rem"></i>', 
                      value: "line"
                    },
                    { 
                      label: '<i class="ph ph-chart-bar" style="font-size: 1.5rem"></i>', 
                      value: "stack"
                    }
                  ]}                
                  onChange={ e => setMcpPositionChartType( e.detail.values[0] ) }
                />
              </div>
            </div>

            {/* Search volume */}
            <div className='widget-wrapper'>
              <CompareValuesChart
                id="search-volume"
                title="Volumes de recherches"
                pictoTitle={ getPicto( 'MagnifyingGlass', { size: "1rem", weight:'bold', color: grey2Color } ) }
                typeChart="column"
                serviceName="volume-trend"
                params={{
                  currentPeriod: currentPeriod,
                  comparePeriod: comparePeriod,
                  webservice: { 
                    where: { 
                      keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords
                    }
                  }
                }}
                aggregateDatasForInsight={ aggregateDatasForInsightCompareSum }
                transparent={ true }
                xAxisLabelVisible={ false }
                tooltipInfosContent={ 'Nombre de recherches sur Google sur les mots-clés suivis' }
                tooltipInfosPosition={ 'right' }
              />
            </div>

            {/* SERP composition */}
            <div className='widget-wrapper'>
              { serpCompositionChartType === 'grouped' ?
                <CompareValuesChart
                  id="serp-composition-grpsnippets"
                  title="Composition de la SERP"
                  pictoTitle={ getPicto( 'SortAscending', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-rate-by-grpsnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords
                      }
                    }
                  }}
                  series={{
                    current: [
                      { 
                        dataField: 'current-Snippets',
                        fillColor: yellowColor,
                        lineColor: yellowColor,
                        lineWidth: 0,
                        opacity: 1
                      },{ 
                        dataField: 'current-Ads',
                        fillColor: orangeColor,
                        lineColor: orangeColor,
                        lineWidth: 0,
                        opacity: 1
                      },{ 
                        dataField: 'current-SEO',
                        fillColor: blueColor,
                        lineColor: blueColor,
                        lineWidth: 0,
                        opacity: 1
                      }
                    ],
                    compare: [
                      { 
                        dataField: 'compare-Snippets',
                        fillColor: yellowColor,
                        lineColor: yellowColor,
                        lineWidth: 0,
                        opacity: 0.3
                      },{ 
                        dataField: 'compare-Ads',
                        fillColor: orangeColor,
                        lineColor: orangeColor,
                        lineWidth: 0,
                        opacity: 0.3
                      },{ 
                        dataField: 'compare-SEO',
                        fillColor: blueColor,
                        lineColor: blueColor,
                        lineWidth: 0,
                        opacity: 0.3
                      }
                    ]
                  }}
                  maxValue={ 100 }
                  unit="&nbsp;%"
                  xAxisLabelVisible={ false }
                  formatResultFct={ results => results?.all !== undefined ? filterTopXFromArrayObject( results.all, 5 ) : {} }
                  aggregateDatasForInsight={ aggregateDatasForInsightGrpSnippetsList }
                  tooltipInfosContent={ 'Pourcentage d’aire occupée par un type de résultat dans le top 10 de la SERP' }
                  tooltipInfosPosition={ 'right' }
                />
                :
                <CompareValuesChart
                  id="serp-composition-typesnippets"
                  title="Composition de la SERP"
                  pictoTitle={ getPicto( 'SortAscending', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="multi-line"
                  serviceName="serp-trend-rate-by-typesnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords
                      }
                    }
                  }}
                  series={ createCustomSeriesForSnippets }
                  unit='&nbsp;%'
                  xAxisLabelVisible={ false }
                  formatResultFct={ results => results?.all !== undefined ? 
                    filterTopXFromArrayObject(
                      Object.fromEntries( 
                        Object.keys( results.all ).map( key => 
                          ([ [key], results.all[key].map( elem => elem.y === null ? { x: elem.x, y: 0 } : { x: elem.x, y: elem.y } ) ]) 
                        ) 
                      ),
                      5
                    )
                    : {} 
                  }
                  aggregateDatasForInsight={ aggregateDatasForInsightTypeSnippetsListAvg }
                  mouseOverCallbackFct={ details => setMouseOverSerpChartDetails( details ) }
                  tooltipInfosContent={ 'Pourcentage d’aire occupée par un type de résultat dans le top 10 de la SERP' }
                  tooltipInfosPosition={ 'right' }
                />
              }
              <div className='button-group-graph-select'>
                <ButtonGroup
                  className='column empty'
                  dataSource={[
                    { 
                      label: '<div class="grouped-button"></div>', 
                      value: "grouped"
                    },
                    { 
                      label: '<div class="split-button"></div>', 
                      value: "split"
                    }
                  ]}                
                  onChange={ e => setSerpCompositionChartType( e.detail.values[0] ) }
                />               
              </div>
            </div>

            {/* SERP rate */}
            <div className='widget-wrapper'>
              { serpRateChartType === 'grouped' ?
                <CompareValuesChart
                  id="serp-rate-grpsnippets"
                  title="Mon occupation (% d'aire)"
                  pictoTitle={ getPicto( 'Seal', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-rate-by-grpsnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={{
                    current: [
                      { 
                        dataField: 'current-Snippets',
                        fillColor: yellowColor,
                        lineColor: yellowColor,
                        lineWidth: 0,
                        opacity: 1
                      },{ 
                        dataField: 'current-Ads',
                        fillColor: orangeColor,
                        lineColor: orangeColor,
                        lineWidth: 0,
                        opacity: 1
                      },{ 
                        dataField: 'current-SEO',
                        fillColor: blueColor,
                        lineColor: blueColor,
                        lineWidth: 0,
                        opacity: 1
                      }
                    ],
                    compare: [
                      { 
                        dataField: 'compare-Snippets',
                        fillColor: yellowColor,
                        lineColor: yellowColor,
                        lineWidth: 0,
                        opacity: 0.3
                      },{ 
                        dataField: 'compare-Ads',
                        fillColor: orangeColor,
                        lineColor: orangeColor,
                        lineWidth: 0,
                        opacity: 0.3
                      },{ 
                        dataField: 'compare-SEO',
                        fillColor: blueColor,
                        lineColor: blueColor,
                        lineWidth: 0,
                        opacity: 0.3
                      }
                    ]
                  }}
                  unit="&nbsp;%"
                  xAxisLabelVisible={ false }
                  aggregateDatasForInsight={ aggregateDatasForInsightGrpSnippetsList }
                  totalDisplay={ true }
                  tooltipInfosContent={ 'Pourcentage d’aire occupée par le site par type de résultat dans le top 10 de la SERP' }
                  tooltipInfosPosition={ 'right' }
                />    
                :
                <CompareValuesChart
                  id="serp-rate-typesnippets"
                  title="Mon occupation (% d'aire)"
                  pictoTitle={ getPicto( 'Seal', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="area"
                  serviceName="serp-trend-rate-by-typesnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: 'none',
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={ createCustomSeriesForSnippets }
                  unit='&nbsp;%'
                  xAxisLabelVisible={ false }
                  formatResultFct={ results => filterTopXFromArrayObject( 
                      Object.fromEntries( 
                        Object.keys( results ).map( key => 
                          ([ [key], results[key].map( elem => elem.y === null ? { x: elem.x, y: 0 } : { x: elem.x, y: elem.y } ) ]) 
                        ) 
                      ), 
                      5
                    )
                  }
                  aggregateDatasForInsight={ aggregateDatasForInsightTypeSnippetsListAvg }
                  highlightedValues={ mouseOverSerpChartDetails }
                  totalDisplay={ true }
                  tooltipInfosContent={ 'Pourcentage d’aire occupée par le site par type de résultat dans le top 10 de la SERP' }
                  tooltipInfosPosition={ 'right' }
                />                            
              }
              <div className='button-group-graph-select'>
                <ButtonGroup
                  className='column empty'
                  dataSource={[
                    { 
                      label: '<div class="grouped-button"></div>', 
                      value: "grouped"
                    },
                    { 
                      label: '<div class="split-button"></div>', 
                      value: "split"
                    }
                  ]}                
                  onChange={ e => setSerpRateChartType( e.detail.values[0] ) }
                />               
              </div>
            </div>

            {/* SERP hits */}
            <div className='widget-wrapper'>
              { serpHitsChartType === 'grouped' ?
                <CompareValuesChart
                  id="serp-hits-grpsnippets"
                  title="Mon occupation (nb apparitions)"
                  pictoTitle={ getPicto( 'Lightbulb', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-hits-by-grpsnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={{
                    current: [
                      { 
                        dataField: 'current-Snippets',
                        fillColor: yellowColor,
                        lineColor: yellowColor,
                        lineWidth: 0,
                        opacity: 1
                      },{ 
                        dataField: 'current-Ads',
                        fillColor: orangeColor,
                        lineColor: orangeColor,
                        lineWidth: 0,
                        opacity: 1
                      },{ 
                        dataField: 'current-SEO',
                        fillColor: blueColor,
                        lineColor: blueColor,
                        lineWidth: 0,
                        opacity: 1
                      }
                    ],
                    compare: [
                      { 
                        dataField: 'compare-Snippets',
                        fillColor: yellowColor,
                        lineColor: yellowColor,
                        lineWidth: 0,
                        opacity: 0.3
                      },{ 
                        dataField: 'compare-Ads',
                        fillColor: orangeColor,
                        lineColor: orangeColor,
                        lineWidth: 0,
                        opacity: 0.3
                      },{ 
                        dataField: 'compare-SEO',
                        fillColor: blueColor,
                        lineColor: blueColor,
                        lineWidth: 0,
                        opacity: 0.3
                      }
                    ]
                  }}
                  xAxisLabelVisible={ false }
                  aggregateDatasForInsight={ aggregateDatasForInsightGrpSnippetsList }
                  totalDisplay={ true }
                  tooltipInfosContent={ 'Occupation en volume d’un site par type de résultat dans le top 10 de la SERP' }
                  tooltipInfosPosition={ 'right' }
                />  
                :
                <CompareValuesChart
                  id="serp-hits-typesnippets"
                  title="Mon occupation (nb apparitions)"
                  pictoTitle={ getPicto( 'Lightbulb', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="multi-line"
                  serviceName="serp-trend-hits-by-typesnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={ createCustomSeriesForSnippets }
                  xAxisLabelVisible={ false }
                  formatResultFct={ results => filterTopXFromArrayObject( results, 5 ) }
                  aggregateDatasForInsight={ aggregateDatasForInsightTypeSnippetsListAvg }
                  highlightedValues={ mouseOverSerpChartDetails }
                  totalDisplay={ true }
                  tooltipInfosContent={ 'Occupation en volume d’un site par type de résultat dans le top 10 de la SERP' }
                  tooltipInfosPosition={ 'right' }
                />                
              }
              <div className='button-group-graph-select'>
                <ButtonGroup
                  className='column empty'
                  dataSource={[
                    { 
                      label: '<div class="grouped-button"></div>', 
                      value: "grouped"
                    },
                    { 
                      label: '<div class="split-button"></div>', 
                      value: "split"
                    }
                  ]}                
                  onChange={ e => setSerpHitsChartType( e.detail.values[0] ) }
                />
              </div>
            </div>

          </div>

          <div className='box4'> 
            {
              !isNullOrUndefined( currentPeriod )
              && !isNullOrUndefined( comparePeriod ) ?
              <GridCoreMarket 
                displayMode={ displayMode }
                loadingParams={{
                  clientID: clientID,
                  device: device,
                  location: location + '|' + city,
                  currentPeriod: currentPeriod,
                  comparePeriod: comparePeriod,
                  userId: userId,
                  filters: filters
                }}
                gridSelectedKeywordsGroups={ gridSelectedKeywordsGroups }
                callBackFunction={ setGridParams } 
                reloadTrigger={ reloadTrigger }
              />
              : null
            }
          </div>
        </Fragment>
        : null 
      }
    </div>
  )  
}

export default InstanceMarket;