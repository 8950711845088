/** Dependencies */
import ReactDomServer from 'react-dom/server';

/** From phosphor-icons **/
import { 
  ArrowUpRight,
  ArrowDownRight,
  SignOut,
  SignIn,
  Pulse,
  Link,
  Skull,
  Chalkboard,
  MagnifyingGlass,
  Trophy,
  Mouse,
  ArrowsVertical,
  CaretRight,
  Eye,
  AlignTop,
  ArrowsOutSimple,
  DotsThreeVertical,
  Laptop,
  DeviceMobileSpeaker,
  List,
  Lightbulb,
  ListDashes,
  Seal,
  MapPinLine,
  Tag,
  Question,
  Calendar,
  FloppyDisk,
  Funnel,
  ArrowsClockwise,
  Control,
  ArrowSquareOut,
  ArrowRight,
  SortAscending,
  ChartBar,
  Columns,
  X,
  PenNib,
  LinkSimple,
  ListMagnifyingGlass,
  ShoppingCart,
  SealQuestion,
  Basket,
  Images,
  CurrencyEur,
  MonitorPlay,
  GraduationCap,
  TwitterLogo,
  FileMagnifyingGlass,
  MapPinArea,
  CookingPot,
  NewspaperClipping,
  MapTrifold,
  Binoculars,
  Student,
  ChatCenteredText,
  Slideshow,
  City,
  ReadCvLogo,
  Chats,
  Bed,
  VideoConference,
  CalendarDots,
  AirplaneTilt,
  Bookmarks,
  ListChecks,
  Gauge,
  ChartLine,
  ExclamationMark,
  Empty,
  Info,
  Gear,
  Plus,
  DownloadSimple,
  Trash
} from "@phosphor-icons/react";

/** From local svg folder */
import { ReactComponent as CalendarNMinus1 } from './../assets/img/pictos/calendar-n-1.svg';
import { ReactComponent as CalendarPMinus1 } from './../assets/img/pictos/calendar-p-1.svg';
import { ReactComponent as GoogleLink } from './../assets/img/pictos/GoogleLink.svg';
import { ReactComponent as ButtonChartListV } from './../assets/img/pictos/ButtonChartListV.svg';
import { ReactComponent as StatusNew } from './../assets/img/pictos/status-new.svg';
import { ReactComponent as StatusIgnore } from './../assets/img/pictos/status-ignore.svg';
import { ReactComponent as ArrowPlainDown } from './../assets/img/pictos/arrow-plain-down.svg';

/**
 * @param {string} name 
 * @param {object} options 
 * @param {renderToString} boolean
 * @returns svg picto with name
 */
 export const getPicto = ( name, options={}, renderToString = false ) => 
 {
  let svgPicto = null;

  switch ( name ) 
  {
    // UI
    case "ArrowUpRight":
      svgPicto = <ArrowUpRight {...options} />;
      break;
    case "ArrowDownRight":
      svgPicto = <ArrowDownRight {...options} />;
      break;
    case "SignOut":
      svgPicto = <SignOut {...options} />;
      break;
    case "SignIn":
      svgPicto = <SignIn {...options} />;
      break;    
    case "Pulse":
      svgPicto = <Pulse {...options} />;
      break;
    case "Link":
      svgPicto = <Link {...options} />;
      break;      
    case "Chalkboard":
      svgPicto = <Chalkboard {...options} />;
      break;   
    case "MagnifyingGlass":
      svgPicto = <MagnifyingGlass {...options} />;
      break;
    case "Trophy":
      svgPicto = <Trophy {...options} />;
      break;               
    case "Mouse":
      svgPicto = <Mouse {...options} />;
      break;
    case "ArrowsVertical":
      svgPicto = <ArrowsVertical {...options} />;
      break;    
    case "CaretRight":
      svgPicto = <CaretRight {...options} />;
      break;         
    case "Eye":
      svgPicto = <Eye {...options} />;
      break;  
    case "AlignTop":
      svgPicto = <AlignTop {...options} />;
      break;           
    case "ArrowsOutSimple":
      svgPicto = <ArrowsOutSimple {...options} />;
      break;          
    case "DotsThreeVertical":
      svgPicto = <DotsThreeVertical {...options} />;
      break;
    case "Laptop":
      svgPicto = <Laptop {...options} />;
      break;          
    case "DeviceMobileSpeaker":
      svgPicto = <DeviceMobileSpeaker {...options} />;
      break;
    case "List":
      svgPicto = <List {...options} />;
      break;
    case "ListDashes":
      svgPicto = <ListDashes {...options} />;
      break;
    case "Lightbulb":
      svgPicto = <Lightbulb {...options} />;
      break;
    case "Seal":
      svgPicto = <Seal {...options} />;
      break;
    case "MapPinLine":
      svgPicto = <MapPinLine {...options} />;
      break;
    case "Tag":
      svgPicto = <Tag {...options} />;
      break;
    case "Question":
      svgPicto = <Question {...options} />;
      break;
    case "Calendar":
      svgPicto = <Calendar {...options} />;
      break;
    case "FloppyDisk":
      svgPicto = <FloppyDisk {...options} />;
      break;
    case "Funnel":
      svgPicto = <Funnel {...options} />;
      break;      
    case "ArrowsClockwise":
      svgPicto = <ArrowsClockwise {...options} />;
      break;
    case "Control":
      svgPicto = <Control {...options} />;
      break;      
    case "ArrowSquareOut":
      svgPicto = <ArrowSquareOut {...options} />;
        break;  
    case "ArrowRight":
      svgPicto = <ArrowRight {...options} />;
        break;    
    case "ChartBar":
      svgPicto = <ChartBar {...options} />;
        break;      
    case "Columns":
      svgPicto = <Columns {...options} />;
        break;      
    case "X":
      svgPicto = <X {...options} />;
        break;      
    case "PenNib":
      svgPicto = <PenNib {...options} />;
        break;   
    case "SortAscending":
      svgPicto = <SortAscending {...options} />;
      break;   
    case "LinkSimple":
      svgPicto = <LinkSimple {...options} />;
      break;   
    case "Bookmarks":
      svgPicto = <Bookmarks {...options} />;
      break;   
    case "ListChecks":
      svgPicto = <ListChecks {...options} />;
      break;   
    case "Gauge":
      svgPicto = <Gauge {...options} />;
      break;   
    case "ChartLine":
      svgPicto = <ChartLine {...options} />;
      break;   
    case "ExclamationMark":
      svgPicto = <ExclamationMark {...options} />;
      break;   
    case "Empty":
      svgPicto = <Empty {...options} />;
      break;   
    case "Info":
      svgPicto = <Info {...options} />;
      break;   
    case "Gear":
      svgPicto = <Gear {...options} />;
      break;   
    case "Plus":
      svgPicto = <Plus {...options} />;
      break;   
    case "DownloadSimple":
      svgPicto = <DownloadSimple {...options} />;
      break;   
    case "Trash":
      svgPicto = <Trash {...options} />;
      break;   
      
    /** Snippets */
    case "SEO":
      svgPicto = <ListMagnifyingGlass {...options} />;
      break;
    case "Shopping":
      svgPicto = <ShoppingCart {...options} />;
      break;
    case "PAA":
      svgPicto = <SealQuestion {...options} />;
      break;
    case "Popular Products":
      svgPicto = <Basket {...options} />;
      break;
    case "Images":
      svgPicto = <Images {...options} />;
      break;
    case "Ads":
      svgPicto = <CurrencyEur {...options} />;
      break;
    case "People Also Search":
      svgPicto = <MagnifyingGlass {...options} />;
      break;
    case "Videos":
      svgPicto = <MonitorPlay {...options} />;
      break;
    case "Knowledge Panel":
      svgPicto = <GraduationCap {...options} />;
      break;
    case "Twitter":
      svgPicto = <TwitterLogo {...options} />;
      break;
    case "Find Results On":
      svgPicto = <FileMagnifyingGlass {...options} />;
      break;
    case "Local Results":
      svgPicto = <MapPinArea {...options} />;
      break;
    case "Recipes":
      svgPicto = <CookingPot {...options} />;
      break;
    case "Top Stories":
      svgPicto = <NewspaperClipping {...options} />;
      break;
    case "Map":
      svgPicto = <MapTrifold {...options} />;
      break;
    case "Top Sights":
      svgPicto = <Binoculars {...options} />;
      break;
    case "Featured Snippet":
      svgPicto = <Trophy {...options} />;
      break;
    case "Scholar":
      svgPicto = <Student {...options} />;
      break;
    case "Answer Box":
      svgPicto = <ChatCenteredText {...options} />;
      break;
    case "Carousel":
      svgPicto = <Slideshow {...options} />;
      break;
    case "Local Services":
      svgPicto = <City {...options} />;
      break;
    case "Jobs":
      svgPicto = <ReadCvLogo {...options} />;
      break;
    case "Questions and Answers":
      svgPicto = <Chats {...options} />;
      break;
    case "Hotels Results":
      svgPicto = <Bed {...options} />;
      break;
    case "Visual Stories":
      svgPicto = <VideoConference {...options} />;
      break;
    case "Events":
      svgPicto = <CalendarDots {...options} />;
      break;
    case "Google Flights":
      svgPicto = <AirplaneTilt {...options} />;
      break;
      
    /** Custom */
    case 'CalendarNMinus1':
      svgPicto = <CalendarNMinus1 {...options} />;
      break;
    case 'CalendarPMinus1':
      svgPicto = <CalendarPMinus1 {...options} />;
      break;
    case 'GoogleLink':
      svgPicto = <GoogleLink {...options} />;
      break;
    case 'ButtonChartListV':
      svgPicto = <ButtonChartListV {...options} />;
      break;
    case 'StatusNew':
      svgPicto = <StatusNew {...options} />;
      break;
    case 'StatusIgnore':
      svgPicto = <StatusIgnore {...options} />;
      break;
    case 'ArrowPlainDown':
      svgPicto = <ArrowPlainDown {...options} />;
      break;
    
    // Default
    default:
      svgPicto = <Skull {...options} />;
      break;
  }

  // render to string if innerHTML
  if( renderToString === true )
   svgPicto = ReactDomServer.renderToString( svgPicto );

  return svgPicto;
}