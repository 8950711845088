/** Dependencies */
import { Fragment } from 'react';
import { Tooltip } from 'smart-webcomponents-react/tooltip';

/** Helpers */
import { isNullOrUndefined } from './../../helpers/functions';
import { getPicto } from './../../helpers/pictos';

/** SCSS */
import './TooltipInfos.scss';

function TooltipInfos( props )
{
  const {
    content,
    position,
    useBrowserTitle = false
  } = props;

  // generate random id
  const randomId = Math.round( Math.random() * 1000 ) + '-tooltip-infos-' + Math.round( Math.random() * 1000 );

  return(
    <div className='tooltip-infos'>
      {
        !isNullOrUndefined( content ) ?
          <Fragment>
            <div id={ randomId } title={ useBrowserTitle ? content : '' }>{ getPicto( 'Info', { size: '1rem' } ) }</div>
            {
              !useBrowserTitle ? 
                <Tooltip selector={ randomId } offset={ [ 2, 0 ] } position={ !isNullOrUndefined( position ) ? 'right' : 'top' } arrow>{ content }</Tooltip>
                : null
            }         
          </Fragment>
          : null
      }      
    </div>
  )
}

export default TooltipInfos;