/** Dependencies */
import { Button } from 'smart-webcomponents-react/button';

/** Helpers */
import { isNullOrUndefined } from './../../helpers/functions';

/** SCSS */
import './AreYouSure.scss';

function AreYouSure( props )
{
  const 
  {
    text,
    cancelCallBackFct,
    confirmCallBackFct,
    isOpened,
    openCloseToggler // use for WindowButton component
  } = props;

  return(
    <div className={ isOpened ? 'areyousure-container' : 'areyousure-container hide' }>
      <p>{ text }</p>
      <div className='confirm-container' id='areyousure-confirm-container'>
        <Button 
          className='flat empty'
          onClick={ 
            typeof cancelCallBackFct === 'function' ?
              cancelCallBackFct
            : typeof openCloseToggler === 'function' ?
              openCloseToggler
            : void( 0 )
          }
        >Annuler</Button>

        <Button 
          className='flat fill' 
          onClick={ () => 
          {
            // call confirm callback function
            if( typeof confirmCallBackFct === 'function' ) confirmCallBackFct();

            // close window
            if( typeof openCloseToggler === 'function' ) openCloseToggler();
          }}
        >Confirmer</Button>
      </div>
    </div>
  )
}

export default AreYouSure;