/** Dependencies */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

/** Components */
import App from './App';
import Maintenance from './pages/Maintenance/Maintenance';
import InstanceMarket from './pages/InstanceMarket/InstanceMarket';
import InstanceKeywords from './pages/InstanceKeywords/InstanceKeywords';
import Admin from './pages/Admin/Admin';

/** Redux */
import store from './store';

/** SCSS */
import './index.scss';

/** Router */
const router = createBrowserRouter([
  {
    path: "/",
    element: process.env.REACT_APP_UNDER_MAINTENANCE === 'true' ? <Maintenance /> : <App />,
    children: [
      {
        path: "",
        element: <InstanceMarket />
      },{
        path: "market/:clientID/:location/:device",
        element: <InstanceMarket />
      },{
        path: "keywords/:clientID/:location/:device",
        element: <InstanceKeywords />
      },{
        path: "admin/keywords/:clientID/:location/:device",
        element: <Admin />
      }
    ]
  }
],{
  basename: process.env.PUBLIC_URL
});

ReactDOM.createRoot( document.getElementById( 'root' ) ).render(
  <React.StrictMode>
    <Provider store={ store }>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);