/** Dependencies */
import { createElement, Fragment, useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { DropDownList, ListItem } from 'smart-webcomponents-react/dropdownlist';

/** Helpers */
import { sortArrayByObjectField } from './../../helpers/functions';

/** SCSS */
import './DropDownMenuNested.scss';

function DropDownMenuNested( props )
{
  const {
    labels,
    dropDownMenuValues,
    initCurrentValues,
    callBackFunction
  } = props;

  /** Init State */
  const [ dropDownListSubCatValues, setDropDownListSubCatValues ] = useState( null );
  const [ dropDownListSubSubCatValues, setDropDownListSubSubCaValues ] = useState( null );
  const [ currentValues, setCurrentValues ] = useState( null );

  /** Init Refs */
  const dropDownCatRef = useRef( null );
  const dropDownSubCatRef = useRef( null );
  const dropDownSubSubCatRef = useRef( null );

  const handleDropDownMenuChange = ( values, detailsEvent ) => 
  {
    // update current values
    if( values !== null && values !== undefined )
    {
      if( detailsEvent?.selected === true )
      {
        // set current values
        if( currentValues === null )
          setCurrentValues( [ values ] ); 
        else if( !currentValues.includes( values ) )
          setCurrentValues(
            cleanValuesWithChildren([ 
              ...currentValues,
              ...[ values ] 
            ])
          ); 

      } else {

        // get new current values
        let newCurrentValues = [ ...currentValues ].filter( value => !value.startsWith( values ) );

        // get splitted values
        const splittedValues = values.split( '|' );

        // add parent value if selected value doesn't come from level1
        if( splittedValues.length > 1  )
          newCurrentValues.push( splittedValues.slice( 0, -1 ).join( '|' ) );

        // set current values
        setCurrentValues( newCurrentValues );
      }
    }
  }

  /** Remove parents values */
  const cleanValuesWithChildren = values => 
  {
    return values.filter( value => values.filter( elem => elem.startsWith( value + '|' ) ).length > 0 ? false : true  );
  }

  /** Update token template in selected area */
  const updateTokensTemplate = () => 
  {
    // get menus by id
    const dropDownMenuList = document.querySelectorAll( '.dropdownmenu' );

    Array.from( dropDownMenuList ).forEach( dropdownmenu => 
      Array.from( dropdownmenu.getElementsByClassName('smart-token') ).forEach( token => 
      {
        // get token dom element
        const spanLabelElement = token.getElementsByClassName( 'smart-drop-down-list-selection-label' )[0];

        // get token dom element visible label
        const spanLabelDisplayElement = token.getElementsByClassName( 'smart-drop-down-list-selection-display-label' )[0];

        if( spanLabelDisplayElement === undefined )
        {
          // get token value
          const tokenValue = spanLabelElement.innerHTML.split( ' - ' )[0].split( ' - ' )[0]; // spanLabelElement.innerHTML.split( ' - ' )[0].split( ' / ' ).map( elem => elem.substring( 0, 5 ) + '...' ).join( ' / ')

          // create span element with picto
          const spanElement = createElement( 
            'span', { className: 'smart-drop-down-list-selection-display-label' }, 
            tokenValue
          );

          // update innerHTML to token domelement
          token.innerHTML = renderToString( spanElement ) + token.innerHTML;
        }
      })
    );
  }

  /** Update selected items in drop down menu with currentValues */
  const updateSelectedDropDownMenuItems = () => 
  {
    if( currentValues !== null )
    {
      let indexesCat = null;
      let indexesSubCat = null;
      let indexesSubSubCat = null;

      currentValues.forEach( value => 
      {
        const cats = value.split( '|' );
  
        // cat case
        if( cats.length > 0 ) 
          indexesCat = [ 
            ...( indexesCat !== null ? indexesCat : [] ), 
            ...[ Object.keys( dropDownMenuValues ).sort().findIndex( elem => elem === cats[0] ) ] 
          ];
        
        // subcat case
        if( cats.length > 1 ) 
          indexesSubCat = [ 
            ...( indexesSubCat !== null ? indexesSubCat : [] ), 
            ...[ dropDownListSubCatValues.map( elem => elem.value ).findIndex( elem => elem === cats.slice( 0, 2 ).join( '|' ) ) ] 
          ];
        
        // subsubcat case
        if( cats.length > 2 ) 
          indexesSubSubCat = [ 
            ...( indexesSubSubCat !== null ? indexesSubSubCat : [] ), 
            ...[ dropDownListSubSubCatValues.map( elem => elem.value ).findIndex( elem => elem === cats.slice( 0, 3 ).join( '|' ) ) ] 
          ];        
      });

      if( indexesCat !== null ) 
        dropDownCatRef.current.selectedIndexes = [...new Set( indexesCat )]; 
      else 
        dropDownCatRef.current.selectedIndexes = [];

      if( indexesSubCat !== null )
        dropDownSubCatRef.current.selectedIndexes = [...new Set( indexesSubCat )];
      else 
        dropDownSubCatRef.current.selectedIndexes = [];

      if( indexesSubSubCat !== null ) 
        dropDownSubSubCatRef.current.selectedIndexes = [...new Set( indexesSubSubCat )];
      else
        dropDownSubSubCatRef.current.selectedIndexes = [];

      // update tokens template
      updateTokensTemplate();
    }
  }

  /** Init subcat and subsubcat drop down menu values */
  useEffect( () => 
  {
    if(
      typeof dropDownMenuValues === 'object' 
      && Object.keys( dropDownMenuValues ).length > 0 
    ){
      // set sub cat values
      setDropDownListSubCatValues(
        Object.keys( dropDownMenuValues ).map( item => 
          Object.keys( dropDownMenuValues[item].cat2 ).map( subitem => ({
            label: item + ' / ' + subitem + ' - ' + dropDownMenuValues[item].cat2[subitem].totalkeywords,
            value: item + '|' + subitem,
          })) 
        ).flat()
      );

      // set sub sub cat values
      setDropDownListSubSubCaValues(
        Object.keys( dropDownMenuValues ).map( item => 
          Object.keys( dropDownMenuValues[item].cat2 ).map( subitem => 
            Object.keys( dropDownMenuValues[item].cat2[subitem].cat3 ).map( subsubitem => ({
              label: item + ' / ' + subitem + ' / ' + subsubitem + ' - ' + dropDownMenuValues[item].cat2[subitem].cat3[subsubitem].totalkeywords,
              value: item + '|' + subitem + '|' + subsubitem
            })) 
          ) 
        ).flat(2)
      );
    }

  }, [ JSON.stringify( dropDownMenuValues ) ])

  /** Update selected items menus and call callback function with current values */
  useEffect( () => 
  {
    // update selected drop down menus items
    updateSelectedDropDownMenuItems();

    // call callback function
    if( 
      currentValues !== null 
      && typeof callBackFunction === 'function'
    )
      callBackFunction( { values: currentValues } );

  }, [ JSON.stringify( currentValues ) ]);

  useEffect( () => 
  {
    if( 
      dropDownListSubCatValues !== null
      && dropDownListSubSubCatValues !== null
      && initCurrentValues?.values !== undefined 
      && (
        currentValues === null
        || (
          currentValues !== null
          && Array.isArray( currentValues )
          && currentValues.length === initCurrentValues.values.length
          && currentValues.filter( elem => !initCurrentValues.values.includes( elem ).length === 0 ) 
        )
      )
    )
      setCurrentValues( initCurrentValues.values );

  }, [ 
    JSON.stringify( initCurrentValues ), 
    JSON.stringify( dropDownListSubCatValues ), 
    JSON.stringify( dropDownListSubSubCatValues ) 
  ]);

  return(
    
      // display when dropdownmenuvalues is loaded
      typeof dropDownMenuValues === 'object' 
      && Object.keys( dropDownMenuValues ).length > 0 
      && dropDownListSubCatValues !== null
      && dropDownListSubSubCatValues !== null ?

        <div className='dropdownmenu-nested-container'>

          <DropDownList
            ref={ dropDownCatRef }
            className='dropdownmenu'
            dropDownMaxHeight={ 300 }
            placeholder={ Array.isArray( labels ) && labels[0] !== undefined ? labels[0] : '' }
            onChange={ e => handleDropDownMenuChange( Object.keys( dropDownMenuValues ).sort()[ e.detail.index ], e.detail ) }
            filterable
            selectionMode='zeroOrMany'
            selectionDisplayMode="tokens"
          >
            { Object.keys( dropDownMenuValues ).sort().map( ( item, index ) => <ListItem key={ index }>{ item } - { dropDownMenuValues[item].totalkeywords }</ListItem> ) }
          </DropDownList>

          <DropDownList
            ref={ dropDownSubCatRef }
            className='dropdownmenu'
            dropDownMaxHeight={ 300 }
            placeholder={ Array.isArray( labels ) && labels[1] !== undefined ? labels[1] : '' }
            onChange={ e => e.detail.index !== -1 ? handleDropDownMenuChange( sortArrayByObjectField( dropDownListSubCatValues, 'label' )[ e.detail.index ].value, e.detail ) : null }
            filterable
            grouped
            selectionMode='zeroOrMany'
            selectionDisplayMode="tokens"
          >
            { sortArrayByObjectField( dropDownListSubCatValues, 'label' ).map( ( item, index ) => <ListItem key={ index }>{ item.label }</ListItem> ) }
          </DropDownList>

          <DropDownList
            ref={ dropDownSubSubCatRef }
            className='dropdownmenu'
            dropDownMaxHeight={ 300 }
            placeholder={ Array.isArray( labels ) && labels[2] !== undefined ? labels[2] : '' }
            onChange={ e => e.detail.index !== -1 ? handleDropDownMenuChange( sortArrayByObjectField( dropDownListSubSubCatValues, 'label' )[ e.detail.index ].value, e.detail ) : null }
            filterable
            grouped
            selectionMode='zeroOrMany'
            selectionDisplayMode="tokens"
          >
            { sortArrayByObjectField( dropDownListSubSubCatValues, 'label' ).map( ( item, index ) => <ListItem key={ index }>{ item.label }</ListItem> ) }
          </DropDownList>

        </div>

      : null
  );
}

export default DropDownMenuNested